import { useState, useEffect, useCallback } from "react";

import axios from "axios";

import { authMutations } from "../graphql/mutations";

export const useOnlineStatus = (checkInterval = 30000) => {
  const { updateOnline } = authMutations;
  const [onlineLocal, setOnlineLocal] = useState(true);

  const setOnline = useCallback(() => {
    console.log("We are online!");
    setOnlineLocal(true);
    updateOnline(true);
  }, []);

  const setOffline = useCallback(() => {
    console.log("We are offline!");
    updateOnline(false);
    setOnlineLocal(false);
  }, []);

  useEffect(() => {
    const checkOnlineStatus = () => {
      const controller = new AbortController();
      setTimeout(() => controller.abort(), 3000);
      fetch("https://api.ipify.org?format=json", { signal: controller.signal })
        .then(() => onlineLocal || setOnline())
        .catch(() => onlineLocal && setOffline());
    };

    checkOnlineStatus(); // Initial check
    const interval = setInterval(checkOnlineStatus, checkInterval);

    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      clearInterval(interval);
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, [onlineLocal, setOnline, setOffline, checkInterval]);

  return { onlineLocal, setOnline, setOffline };
};
