import React from "react";

import { Edit } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { MUTATE_ACTION } from "../../../../graphql/mutations/admin/arm";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";

export default function EditAction({ action, canEdit }) {
  const [mutateAction] = useMutation(MUTATE_ACTION, {
    onCompleted() {
      onCompletedFunc("Action has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [open, setOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [actionName, setActionName] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (action.a.id) {
      setIsActive(action.a.isActive);
      setActionName(action.a.name);
    }
  }, [action]);

  const saveEdit = () => {
    // Update the global action
    mutateAction({
      variables: {
        id: Number(action.a.id),
        name: actionName,
        isActive,
      },
    });
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="yellow0"
        style={{
          zIndex: 1000,
        }}
        endIcon={<Edit />}
        onClick={handleOpen}
      >
        EDIT GLOBAL ACTION
      </Button>

      <CustomDialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogTitle style={{ ...pageTitleStyles }}>EDIT ACTION.</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            {canEdit && (
              <Grid item container alignItems="center">
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={isActive}
                      onChange={(event) => setIsActive(event.target.checked)}
                    />
                  }
                  label={isActive ? "ACTIVE" : "INACTIVE"}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomTextField
                variant="standard"
                value={actionName}
                label="Action Name:"
                onChange={(event) => setActionName(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={saveEdit}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
