import React from "react";

import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import ActionStep from "./ActionStep";
import { styles } from "./styles";

export default function ARMSection({ ha }) {
  const unique = (arr) =>
    arr.reduce((results, item) => {
      if (
        !results.some(
          (i) =>
            i.arm.a.name === item.arm.a.name && i.arm.a.id === item.arm.a.id
        )
      ) {
        results.push(item);
      }
      return results;
    }, []);

  const actions = unique(
    ha.haArms.filter((haArm) => !haArm.arm.r && !haArm.arm.m)
  );

  const d = new Date(ha.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  let startTime = moment();
  if (ha.time) {
    const timeArr = ha.time.split(":");
    startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));
  } else {
    startTime = moment(new Date(ha.timeCompleted));
  }

  return (
    <Page size="A4" orientation="landscape" wrap style={{ padding: "20px" }}>
      <View
        style={{
          padding: "10px",
          borderTop: "3px solid grey",
          margin: "20px auto",
          width: "95%",
        }}
      ></View>
      <View style={styles.page}>
        <View
          style={[
            styles.formSection,
            { borderTop: "none", borderLeft: "none" },
          ]}
        >
          <Text style={styles.sectionLabel}>Hazard Analysis Name</Text>
          <Text style={{ fontSize: "12px" }}>{ha.name}</Text>
        </View>
        <View
          style={[
            styles.formSection,
            { borderTop: "none", borderLeft: "none" },
          ]}
        >
          <Text style={styles.sectionLabel}>Date</Text>
          <Text style={{ fontSize: "12px" }}>
            {moment(convertedDate).format("MM/DD/YYYY")}
          </Text>
        </View>
        <View
          style={[
            styles.formSection,
            { borderTop: "none", borderLeft: "none" },
          ]}
        >
          <Text style={styles.sectionLabel}>Created By</Text>
          <Text style={{ fontSize: "12px" }}>{ha.submitter.username}</Text>
        </View>
        <View
          style={[
            styles.formSection,
            {
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            },
          ]}
        >
          <Text style={styles.sectionLabel}>User Company</Text>
          <Text style={{ fontSize: "12px" }}>{ha.submitter.company?.name}</Text>
        </View>
        <View
          style={[
            styles.formSection,
            { borderTop: "none", borderLeft: "none" },
          ]}
        >
          <Text style={styles.sectionLabel}>Start Time</Text>
          <Text style={{ fontSize: "12px" }}>
            {moment(startTime).format("hh:mm A")}
          </Text>
        </View>
        <View
          style={[
            styles.formSection,
            { borderTop: "none", borderLeft: "none" },
          ]}
        >
          <Text style={styles.sectionLabel}>Location</Text>
          <Text style={{ fontSize: "12px" }}>
            {ha.haOwnerSiteProjects.length > 0
              ? ha.haOwnerSiteProjects[0].ownerSiteProject.site.name
              : ""}{" "}
            [
            {ha.haOwnerSiteProjects.length > 0
              ? ha.haOwnerSiteProjects[0].ownerSiteProject.owner.name
              : ""}
            ]
          </Text>
        </View>
        <View
          style={[
            styles.formSection,
            { borderTop: "none", borderLeft: "none" },
          ]}
        >
          <Text style={styles.sectionLabel}>Project</Text>
          <Text style={{ fontSize: "12px" }}>
            {ha.haOwnerSiteProjects.length > 0
              ? ha.haOwnerSiteProjects
                  .map((haOsp) => haOsp.ownerSiteProject.project?.name ?? "")
                  .join(", ")
              : ""}
          </Text>
        </View>
        <View
          style={[
            styles.formSection,
            {
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
            },
          ]}
        >
          <Text style={styles.sectionLabel}>Template</Text>
          {ha.haTemplate.length > 0 ? (
            <Text style={{ fontSize: "12px" }}>
              {ha.haTemplate.map((ha) => ha.template.name).join(", ")}
            </Text>
          ) : (
            <Text style={{ fontSize: "12px" }}>---</Text>
          )}
        </View>
        <View
          style={{
            width: "100%",
            height: "20px",
            backgroundColor: "black",
          }}
        ></View>
        <View
          style={[styles.armSectionHeader, { borderRight: "1px solid black" }]}
        >
          <Text
            style={{
              padding: "20px",
              fontSize: "12px",
              borderTop: "5px solid #8297A0",
            }}
          >
            Job Step
          </Text>
        </View>
        <View
          style={[styles.armSectionHeader, { borderRight: "1px solid black" }]}
        >
          <Text
            style={{
              padding: "20px",
              fontSize: "12px",
              borderTop: "5px solid #8297A0",
            }}
          >
            Potential Risks
          </Text>
        </View>
        <View style={styles.armSectionHeader}>
          <Text
            style={{
              padding: "20px",
              fontSize: "12px",
              borderTop: "5px solid #8297A0",
            }}
          >
            Preventative Controls
          </Text>
        </View>
        <View style={[styles.column, { width: "100%" }]}>
          {sortByKeys(actions, "actionOrdering").map((action) => (
            <ActionStep key={action.arm.id} action={action} ha={ha} />
          ))}
        </View>
      </View>
    </Page>
  );
}

ARMSection.propTypes = {
  ha: PropTypes.object.isRequired,
};
