import React from "react";

import { Check, CheckBoxOutlineBlank, InfoOutlined } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { CREATE_PERMISSION_GROUP } from "../../../graphql/mutations/admin/permissions";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import { GET_PERMISSION_GROUPS } from "../../../graphql/queries/permissions";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function CreateGroup({ permissions: allPermissions }) {
  const [open, setOpen] = React.useState(false);
  const [getCurrentUser, { data }] = useLazyQuery(GET_CURRENT_USER);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      getCurrentUser();
    }
  }, [open]);

  const [createGroup] = useMutation(CREATE_PERMISSION_GROUP, {
    onCompleted() {
      handleCancel();
      onCompletedFunc("Permission group has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_PERMISSION_GROUPS, fetchPolicy: "no-cache" }],
  });

  const [groupName, setGroupName] = React.useState("");
  const [addedPermissions, setAddedPermissions] = React.useState([]);

  const onCreateGroup = () => {
    if (groupName !== "" && addedPermissions.length > 0) {
      createGroup({
        variables: {
          company: Number(data?.currentUser.company.id),
          name: groupName,
          permissions: addedPermissions.map((p) => Number(p.id)),
        },
      });
    } else {
      onErrorFunc("Include a name and at least one permission");
    }
  };

  const onClear = () => {
    setGroupName("");
    setAddedPermissions([]);
  };

  const add = (p) => {
    setAddedPermissions((prev) => [...prev, p]);
  };

  const remove = (p) => {
    setAddedPermissions((prev) => prev.filter((prev) => prev.id !== p.id));
  };

  const handleCancel = () => {
    onClear();
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="yellow0"
        sx={{ mr: 1 }}
        onClick={() => setOpen(true)}
      >
        CREATE GROUP
      </Button>

      <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
        <DialogTitle
          style={{
            ...pageTitleStyles,
          }}
        >
          CREATE PERMISSION GROUP.
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                label="Group Name:"
                variant="standard"
                value={groupName}
                onChange={(event) => setGroupName(event.target.value)}
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={7}>
                <Typography style={{ color: "white" }}>
                  INCLUDED PERMISSIONS:
                </Typography>
                <Grid
                  container
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    backgroundColor: "white",
                    width: "95%",
                    marginTop: "10px",
                    borderRadius: 5,
                  }}
                >
                  {addedPermissions.length === 0 && (
                    <Typography style={{ padding: "20px", color: "black" }}>
                      No added permissions.
                    </Typography>
                  )}

                  {addedPermissions.map((perm) => (
                    <Grid
                      item
                      xs={12}
                      key={perm.id}
                      style={{
                        padding: "10px",
                        height: "fit-content",
                      }}
                      container
                      alignItems="center"
                    >
                      <Grid item xs={2}>
                        <IconButton
                          style={{ marginRight: "10px" }}
                          onClick={() => remove(perm)}
                        >
                          <Check />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} container>
                        <Typography
                          style={{ marginRight: "15px", color: "black" }}
                        >
                          {perm.name}
                        </Typography>
                        <Tooltip
                          title={<Typography>{perm.description}</Typography>}
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Typography style={{ color: "white" }}>
                  UNINCLUDED PERMISSIONS:
                </Typography>
                <Grid
                  container
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    backgroundColor: "white",
                    width: "100%",
                    marginTop: "10px",
                    borderRadius: 5,
                  }}
                >
                  {allPermissions?.permissions
                    .filter(
                      (p) =>
                        p.isActive &&
                        addedPermissions.findIndex((perm) => perm.id === p.id) <
                          0
                    )
                    .map((p) => (
                      <Grid
                        item
                        xs={12}
                        key={p.id}
                        style={{
                          padding: "10px",
                          height: "fit-content",
                        }}
                        container
                        alignItems="center"
                      >
                        <Grid item xs={2}>
                          <IconButton
                            style={{ marginRight: "10px" }}
                            onClick={() => add(p)}
                          >
                            <CheckBoxOutlineBlank />
                          </IconButton>
                        </Grid>
                        <Grid item xs={10} container alignItems="center">
                          <Typography
                            style={{ marginRight: "5px", color: "black" }}
                          >
                            {p.name}
                          </Typography>
                          <Tooltip
                            title={<Typography>{p.description}</Typography>}
                          >
                            <InfoOutlined sx={{ color: "black" }} />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={onCreateGroup}
          >
            CREATE
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

CreateGroup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  permissions: PropTypes.array,
};
