import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_SITES } from "../../../../graphql/queries";
import sortByKeys from "../../../../utils/sortByKeys";

export default function Site({
  site,
  setSite,
  owner,
  setProject,
  allSites,
  setAllSites,
}) {
  // Queries
  const { data, loading } = useQuery(GET_SITES);

  const handleChange = (event, newValue) => {
    setSite(newValue);
    setProject([]);
  };

  const getOptions = () => {
    if (data) {
      if (owner && owner.length > 0) {
        const ownerIds = owner.map((o) => Number(o.owner.id));
        return sortByKeys(
          data.ownerSiteProjects.filter(
            (osp) => ownerIds.includes(Number(osp.owner.id)) && osp.site?.name
          ),
          "site.name"
        );
      }
      return sortByKeys(
        data.ownerSiteProjects.filter((osp) => osp.site?.name),
        "site.name"
      );
    } else return [];
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Autocomplete
        id="select-site"
        options={getOptions()}
        value={site || []}
        multiple
        getOptionLabel={(option) =>
          option.owner.name === ""
            ? ""
            : `${option.site.name} [${option.owner.name}]`
        }
        onChange={handleChange}
        loading={loading}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id == value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={`${option.id}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.site.name} [${option.owner.name}]`}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Site(s):"
            value={params}
            id={params.id}
          />
        )}
        disabled={allSites || owner.length === 0}
      />
      <Grid container alignItems="center">
        <Checkbox
          checked={allSites}
          onChange={(e) => setAllSites(e.target.checked)}
          disabled={owner.length === 0}
        />
        <Typography>Select All Sites</Typography>
      </Grid>
    </>
  );
}
