import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import moment from "moment";

import { permissionsVar } from "../../../../../graphql/localVariables/user";
import { TEMPLATE_EDIT } from "../../../../../graphql/mutations/admin/planning-template";
import { GET_COMPANY_ARMS_ADMIN } from "../../../../../graphql/queries/admin/arm";
import { GET_COMPANY } from "../../../../../graphql/queries/admin/company";
import {
  GET_TEMPLATES,
  GET_TEMPLATE_DATA,
} from "../../../../../graphql/queries/admin/planning-template";
import { GET_CURRENT_USER } from "../../../../../graphql/queries/auth";
import MobilePDFWarning from "../../../../CustomComponents/MobilePDFWarning";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../CustomComponents/OnErrorFunction";
import SelectedUnselected from "../../../../CustomComponents/SelectedUnselected";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { CustomTextField } from "../../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../CustomStyles/pageTitle";
import SelectOwnerSiteProject from "../SelectOwnerSiteProject";
import TemplatePDF from "./TemplatePDF";

export default function EditTemplate({ row, open, handleClose, company }) {
  const permissions = useReactiveVar(permissionsVar);

  const { data: user } = useQuery(GET_CURRENT_USER);
  const [getTemplate, { data }] = useLazyQuery(GET_TEMPLATE_DATA, {
    fetchPolicy: "no-cache",
  });
  const [getCompanyActions, { data: actions }] = useLazyQuery(
    GET_COMPANY_ARMS_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getCompanyRisks, { data: risks }] = useLazyQuery(
    GET_COMPANY_ARMS_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );
  const [getCompanyMit, { data: mit }] = useLazyQuery(GET_COMPANY_ARMS_ADMIN, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (open) {
      getTemplate({ variables: { id: Number(row.id) } });
    }
  }, [open]);

  React.useEffect(() => {
    if (open && user) {
      const companyId = Number(user.currentUser.company.id);

      getCompanyActions({
        variables: {
          companyId,
          rNull: true,
          mNull: true,
        },
      });
      getCompanyRisks({
        variables: {
          companyId,
          rNull: false,
          mNull: true,
        },
      });
      getCompanyMit({
        variables: {
          companyId,
          rNull: false,
          mNull: false,
        },
      });
    }
  }, [open, user]);

  const [editTemplate, { loading }] = useMutation(TEMPLATE_EDIT, {
    onCompleted() {
      setEdit(false);
      onCompletedFunc("Template has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_TEMPLATES, fetchPolicy: "no-cache" },
      company && {
        query: GET_COMPANY,
        variables: { id: Number(company.id) },
        fetchPolicy: "no-cache",
      },
      {
        query: GET_TEMPLATE_DATA,
        variables: { id: Number(row.id) },
        fetchPolicy: "no-cache",
      },
    ],
  });
  const [edit, setEdit] = React.useState(false);

  const [isActive, setIsActive] = React.useState(row.isActive);
  const [name, setName] = React.useState(row.name);
  const [startDate, setStartDate] = React.useState(new Date(row.startDate));
  const [endDate, setEndDate] = React.useState(new Date(row.endDate));

  const [osp, setOsp] = React.useState([]);

  const [selectedActions, setSelectedActions] = React.useState([]);
  const [unselectedActions, setUnselectedActions] = React.useState([]);
  const [viewMit, setViewMit] = React.useState(false);

  const setInitialState = () => {
    if (data) {
      const originalOSP = data.ownerSiteProjectTemplates.map((ospt) => ({
        id: ospt.ownerSiteProject.id,
        owner: ospt.ownerSiteProject.owner,
        site: ospt.ownerSiteProject.site,
        project: ospt.ownerSiteProject.project,
      }));
      const originalActions =
        data.templateArms.length > 0
          ? data.templateArms.map((tArm) => tArm.arm)
          : [];

      setIsActive(data.template.isActive);
      setName(data.template.name);
      setStartDate(new Date(data.template.startDate));
      setEndDate(new Date(data.template.endDate));
      setOsp(originalOSP);
      setSelectedActions(originalActions);
    }
  };

  React.useEffect(() => {
    if (actions && data) {
      setInitialState();
      if (data.templateArms.length > 0) {
        setUnselectedActions(
          actions.companyArms
            .filter(
              (carm) =>
                data.templateArms.findIndex(
                  (tarm) => tarm.arm.a.id === carm.arm.a?.id
                ) < 0
            )
            .map((carm) => carm.arm)
        );
      } else {
        setUnselectedActions(actions.companyArms.map((carm) => carm.arm));
      }
    }
  }, [actions, data]);

  const onSave = () => {
    if (osp.length === 0) {
      onErrorFunc(
        "Select a company, site or project to associate to this template."
      );
    } else if (name === "") {
      onErrorFunc("Include a name for this template.");
    } else {
      const originalOSP = data.ownerSiteProjectTemplates.map((ospt) => ({
        id: ospt.ownerSiteProject.id,
        owner: ospt.ownerSiteProject.owner,
        site: ospt.ownerSiteProject.site,
        project: ospt.ownerSiteProject.project,
      }));
      const originalActions =
        data.templateArms.length > 0
          ? data.templateArms.map((tArm) => tArm.arm)
          : [];

      let unassociatedOSP = originalOSP
        .filter((oOSP) => osp.findIndex((o) => o.id === oOSP.id) < 0)
        .map((o) => Number(o.id));
      let unassociatedActions = originalActions
        .filter(
          (oAction) => selectedActions.findIndex((a) => a.id === oAction.id) < 0
        )
        .map((a) => Number(a.id));

      editTemplate({
        variables: {
          id: Number(row.id),
          name,
          isActive,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          associatedOSP: osp.map((osp) => Number(osp.id)),
          unassociatedOSP,
          unassociatedActions,
          associatedActions: selectedActions.map((a) => Number(a.id)),
        },
      });
    }
  };

  const onCancel = () => {
    if (edit) {
      setEdit(false);
      onClear();
    } else {
      handleClose();
    }
  };

  const onClear = () => {
    setInitialState();
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
          fontSize: "2rem",
        }}
      >
        TEMPLATE: <span style={{ color: "#FFB700" }}> {row.name}</span>
      </DialogTitle>
      <DialogContent>
        {edit ? (
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <FormControlLabel
                style={{ color: "white" }}
                control={
                  <CustomSwitch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                    light
                  />
                }
                label={isActive ? "ACTIVE" : "INACTIVE"}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Template Name:"
                variant="standard"
                value={name}
                onChange={(event) => setName(event.target.value)}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MobileDatePicker
                label="Start Date:"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{
                      width: "100%",
                    }}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MobileDatePicker
                label="End Date:"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{
                      width: "100%",
                    }}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectOwnerSiteProject open={open} osp={osp} setOsp={setOsp} />
            </Grid>
            <Grid item xs={12}>
              <SelectedUnselected
                selected={selectedActions}
                unselected={unselectedActions}
                setSelected={setSelectedActions}
                setUnselected={setUnselectedActions}
                updateState={() => {}}
                idKey="a"
                focusList={[]}
                label="Actions"
                selectedLabel
                showSearch
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Grid item>
                {isActive ? (
                  <Chip label="ACTIVE" color="secondary" />
                ) : (
                  <Chip label="INACTIVE" color="primary" />
                )}
                {permissions.includes("EDIT_TEMPLATE") && (
                  <Button
                    variant="contained"
                    color="yellow0"
                    sx={{ ml: 2 }}
                    onClick={() => setEdit(true)}
                  >
                    EDIT
                  </Button>
                )}
              </Grid>
              <Grid item xs={2} container direction="column">
                <Typography>View mitigators?</Typography>
                <FormControlLabel
                  style={{ color: "white" }}
                  control={
                    <CustomSwitch
                      checked={viewMit}
                      onChange={(event) => setViewMit(event.target.checked)}
                      light
                    />
                  }
                  label={viewMit ? "YES" : "NO"}
                />
              </Grid>
              {data && risks && (
                <PDFDownloadLink
                  document={
                    <TemplatePDF
                      template={data.template}
                      arms={data.templateArms}
                      risks={risks}
                      mit={mit}
                      viewMit={viewMit}
                    />
                  }
                  fileName={`TEMPLATE_${data.template.name.replace(
                    /[:<>*?".|\/\s]/g,
                    ""
                  )}`}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mr: 2 }}
                        >
                          DOWNLOAD PDF
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              )}
            </Grid>
            {data && risks ? (
              <>
                <BrowserView>
                  <PDFViewer width="100%" height="800px">
                    <TemplatePDF
                      template={data.template}
                      arms={data.templateArms}
                      risks={risks}
                      mit={mit}
                      viewMit={viewMit}
                    />
                  </PDFViewer>
                </BrowserView>
                <MobileView>
                  <MobilePDFWarning />
                </MobileView>
              </>
            ) : (
              <Grid container justifyContent="center" style={{ width: "100%" }}>
                <CircularProgress color="secondary" />
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {edit && (
          <LoadingButton
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={onSave}
            loading={loading}
          >
            SAVE
          </LoadingButton>
        )}
        <Button variant="contained" onClick={onCancel}>
          {edit ? "CANCEL" : "DONE"}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
