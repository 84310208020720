import React from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import { Info } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { PDFViewer, pdf, PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { EMAIL_AUDIT } from "../../../graphql/mutations/audits";
import {
  GET_AUDIT_SUBMISSION,
  GET_SINGLE_AUDIT_TOPICS,
} from "../../../graphql/queries/audits";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import MobilePDFWarning from "../../CustomComponents/MobilePDFWarning";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import { PDFDocument } from "./PDF/PDFDocument";
import ViewImages from "./ViewImages";

export default function ViewSingleAudit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const permissions = useReactiveVar(permissionsVar);

  const { data } = useQuery(GET_AUDIT_SUBMISSION, {
    variables: { id: Number(id) },
    fetchPolicy: "no-cache",
  });
  const [getAuditTopics, { data: auditTopics }] = useLazyQuery(
    GET_SINGLE_AUDIT_TOPICS
  );
  const { data: currentUser } = useQuery(GET_CURRENT_USER);

  const [sendEmailMutation] = useMutation(EMAIL_AUDIT, {
    onCompleted() {
      onCompletedFunc("Email will be sent!");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const [images, setImages] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      getAuditTopics({
        variables: { id: Number(data.auditSubmission.audit?.id) },
      });

      const img = [];
      data.auditSubmission.responses.forEach((res) => {
        res.images.forEach((i) => {
          img.push(i);
        });
      });
      setImages(img);
    }
  }, [data]);

  const sendEmail = async () => {
    let blobPdf = await pdf(
      PDFDocument(data.auditSubmission, auditTopics, showAll)
    ).toBlob();
    let file = new File([blobPdf], "file_name", { type: "application/pdf" });
    sendEmailMutation({
      variables: {
        id: Number(id),
        file,
      },
    });
  };

  const isOwner =
    data?.auditSubmission.submitter.username ===
    currentUser?.currentUser.username;

  const getPDFButton = () => {
    const d = new Date(data.auditSubmission.timeCreated);
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const convertedDate = new Date(utc);

    return (
      <PDFDownloadLink
        document={PDFDocument(data.auditSubmission, auditTopics, showAll)}
        fileName={`AUDIT_${data.auditSubmission.ownerSiteProject.site.name}_[${
          data.auditSubmission.ownerSiteProject.owner.name
        }]_${moment(new Date(convertedDate)).format("MMDDYYYY")}`.replace(
          /[:<>*?".|/\\\s]/g,
          ""
        )}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) =>
          loading ? (
            <CircularProgress color="secondary" sx={{ mr: 2 }} />
          ) : (
            <Button variant="contained" color="primary" sx={{ mr: 2 }}>
              DOWNLOAD PDF
            </Button>
          )
        }
      </PDFDownloadLink>
    );
  };

  return (
    <Layout>
      <Grid container justifyContent="space-between">
        <Typography sx={pageTitleStyles}>VIEW AUDIT.</Typography>
        <Button
          onClick={() => navigate("/view-audit")}
          style={{ color: "white" }}
        >
          GO BACK
        </Button>
      </Grid>
      {data && auditTopics ? (
        <Grid container>
          <Grid
            item
            xs={12}
            container
            direction="row"
            style={{ marginBottom: "20px" }}
          >
            {(isOwner || permissions.includes("EDIT_OTHER_AUDIT")) && (
              <Button
                onClick={() =>
                  navigate(`/edit-audit/${data.auditSubmission.id}`)
                }
                variant="contained"
                color="yellow0"
                sx={{ mr: 2 }}
              >
                EDIT
              </Button>
            )}
            <Button
              color="secondary"
              sx={{ mr: 2 }}
              variant="contained"
              onClick={sendEmail}
            >
              SEND TO EMAIL
            </Button>
            {getPDFButton()}

            <ViewImages images={images} />

            <Grid item xs={12} md container alignItems="center" sx={{ pl: 2 }}>
              <Grid container item sx={{ flexGrow: 1 }} alignItems="center">
                <Typography style={{ color: "white", textAlign: "center" }}>
                  Show filtered responses
                </Typography>
                <Tooltip title="Show only responses with No answers or responses with images or comments.">
                  <Info style={{ color: "white", marginLeft: "10px" }} />
                </Tooltip>

                <CustomSwitch
                  checked={showAll}
                  onChange={() => setShowAll((prev) => !prev)}
                  value={true}
                />
                <Typography style={{ color: "white" }}>
                  Show all responses
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isTablet ? (
              <MobilePDFWarning />
            ) : (
              <>
                <BrowserView>
                  <PDFViewer width="100%" height="800px">
                    {PDFDocument(data.auditSubmission, auditTopics, showAll)}
                  </PDFViewer>
                </BrowserView>
                <MobileView>
                  <MobilePDFWarning />
                </MobileView>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </Layout>
  );
}
