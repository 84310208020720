export const versionNum = "0.5.10";

export const releaseNotes = [
  {
    versionNum: "0.5.10",
    date: "January 15, 2025",
    notes: ["Incident PDF bug fixes."],
  },
  {
    versionNum: "0.5.9",
    date: "January 13, 2025",
    notes: ["Bug fixes."],
  },
  {
    versionNum: "0.5.8",
    date: "January 9, 2025",
    notes: ["Bug fixes."],
  },
  {
    versionNum: "0.5.7",
    date: "January 7, 2025",
    notes: ["Bug fixes."],
  },
  {
    versionNum: "0.5.6",
    date: "January 6, 2025",
    notes: ["Fix PDF emails."],
  },
  {
    versionNum: "0.5.5",
    date: "December 26, 2024",
    notes: ["Bug fixes."],
  },
  {
    versionNum: "0.5.4",
    date: "December 5, 2024",
    notes: ["Performance improvements."],
  },
  {
    versionNum: "0.5.1",
    date: "June 10, 2024",
    notes: ["Fix company admin issue."],
  },
  {
    versionNum: "0.5.0",
    date: "May 3, 2024",
    notes: ["Optimizing admin pages."],
  },
  {
    versionNum: "0.4.22",
    date: "April 15, 2024",
    notes: [
      "Creating/editing a site for a company with a parent will also show the parent's contractors as options for the child's contractors.",
    ],
  },
  {
    versionNum: "0.4.21",
    date: "March 15, 2024",
    notes: [
      "Those with the permissions (admins) can edit other people's forms.",
      "Fixed some photo bugs on Incident PDFs.",
    ],
  },
  {
    versionNum: "0.4.20",
    date: "March 12, 2024",
    notes: ["Fix issue with files duplicating on Incident Investigations."],
  },
  {
    versionNum: "0.4.19",
    date: "March 1, 2024",
    notes: ["Adjust queries for notifications."],
  },
  {
    versionNum: "0.4.18",
    date: "Febuary 29, 2024",
    notes: ["Add permission for closing incident investigations."],
  },
  {
    versionNum: "0.4.17",
    date: "Febuary 12, 2024",
    notes: [
      "Fixing Contractors in Site creation.",
      "Allow editing site from viewing Contractors.",
    ],
  },
  {
    versionNum: "0.4.16",
    date: "Febuary 2, 2024",
    notes: ["Fixing saving risks in ARM admin."],
  },
  {
    versionNum: "0.4.15",
    date: "Febuary 1, 2024",
    notes: ["Fixing duplicate ARM issue in HA PDFs on mobile."],
  },
  {
    versionNum: "0.4.14",
    date: "January 26, 2024",
    notes: ["Fixed ARM Admin crash."],
  },
  {
    versionNum: "0.4.13",
    date: "January 25, 2024",
    notes: ["Fixes to CrewMember Sign Up."],
  },
  {
    versionNum: "0.4.12",
    date: "January 17, 2024",
    notes: [
      "Fixes to HA ARM bug.",
      "Fix Days Open issue on HA Summary reports.",
    ],
  },
  {
    versionNum: "0.4.11",
    date: "December 15, 2023",
    notes: ["Fixes to ARM admin scoring."],
  },
  {
    versionNum: "0.4.10",
    date: "December 13, 2023",
    notes: [
      "Fix some mobile layout issues.",
      "Fix images shown in the incorrect orientation on OBS PDFs.",
    ],
  },
  {
    versionNum: "0.4.9",
    date: "December 8, 2023",
    notes: [
      "Remove inactive users from user administration tables.",
      "Obs/HA/AUdit/Investigation table search filters will now save even after if you leave the page.",
    ],
  },
  {
    versionNum: "0.4.8.1",
    date: "December 6, 2023",
    notes: ["Allow MAC Safety admins to edit user emails."],
  },
  {
    versionNum: "0.4.8",
    date: "December 5, 2023",
    notes: [
      "Fixed validation of usernames, passwords, and emails when admins create a user.",
      "Allow MAC Safety admins to edit user emails.",
      "Add refetch to try and fix HAs saving with 0 scores.",
    ],
  },
  {
    versionNum: "0.4.7",
    date: "November 30, 2023",
    notes: ["Fixed View Obs date filter."],
  },
  {
    versionNum: "0.4.6",
    date: "November 28, 2023",
    notes: ["Improvements for Custom Reporting interface."],
  },
  {
    versionNum: "0.4.5",
    date: "November 22, 2023",
    notes: [
      "Optimizations and bug fixes.",
      "Working on improvements for Custom Reporting.",
    ],
  },
  {
    versionNum: "0.4.4",
    date: "November 17, 2023",
    notes: [
      "Fixed issue with observation images.",
      "Updated requirements for CrewMember CSV uploads to facilitate international numbers.",
    ],
  },
  {
    versionNum: "0.4.3",
    date: "November 16, 2023",
    notes: [
      "Fixed issue on HA where the actions change order.",
      "Hide contractor field on custom reporting if creating a user report.",
    ],
  },
  {
    versionNum: "0.4.2",
    date: "November 15, 2023",
    notes: ["Fixed bug with observation images not submitting properly."],
  },
  {
    versionNum: "0.4.1",
    date: "November 13, 2023",
    notes: [
      "Optimize ARM admin.",
      "Fixed bug with editing completed HAs from dashboard.",
    ],
  },
  {
    versionNum: "0.4.0",
    date: "November 9, 2023",
    notes: [
      "Fixed small bugs with custom reporting.",
      "Added warning message for Full Database reports.",
    ],
  },
  {
    versionNum: "0.3.7",
    date: "November 7, 2023",
    notes: ["Enhancements to custom reporting."],
  },
  {
    versionNum: "0.3.6",
    date: "November 1, 2023",
    notes: ["Added Custom Reporting feature."],
  },
  {
    versionNum: "0.3.5",
    date: "October 27, 2023",
    notes: [
      "Update HA scoring.",
      "Arm share defaults updated.",
      "Various optimizations",
    ],
  },
  {
    versionNum: "0.3.4",
    date: "October 20, 2023",
    notes: ["Update HAs"],
  },
  {
    versionNum: "0.3.3",
    date: "October 11, 2023",
    notes: ["Fixed View HAs filter issue."],
  },
  {
    versionNum: "0.3.2",
    date: "October 2, 2023",
    notes: [
      "Added child companies to creating templates.",
      "Users will now see correct observations, HAs and audits.",
      "Fixed bug in Observation PDFs where corrections were missing.",
      "Fixed issue with not all templates showing on HA.",
      "Other changes to improve performance and fix bugs.",
    ],
  },
  {
    versionNum: "0.3.1",
    date: "September 20, 2023",
    notes: ["Fixed observation PDF issue"],
  },
  {
    versionNum: "0.3.0",
    date: "September 19, 2023",
    notes: [
      "Fixed duplicate HAs",
      "Updated arm approvals",
      "Added search features",
      "Add permission for creating ARM on an observation",
    ],
  },
  {
    versionNum: "0.2.14",
    date: "September 15, 2023",
    notes: ["Search associated crew on planning admin => site."],
  },
  {
    versionNum: "0.2.13",
    date: "September 8, 2023",
    notes: [
      "Observed sites will now show when creating a template.",
      "Some changes to improve ARM admin pages.",
      "Fix issue where ARMs from selected HA were not highlighted on observations.",
      "Fixed issues with duplicate company ARMs.",
      "Fixed date/time on Audit PDF.",
    ],
  },
  {
    versionNum: "0.2.12",
    date: "September 6, 2023",
    notes: [
      "Changed PWA settings to go back to standalone app. The previous setting was not supported on iOS, so it was defaulting to a browser view.",
    ],
  },
  {
    versionNum: "0.2.11",
    date: "August 28, 2023",
    notes: [
      "Added a company select to ARM administration.",
      "Updated 'Percent Mitigated' score.",
      "Added 'What happened?' to incident investigation PDF.",
    ],
  },
  {
    versionNum: "0.2.10",
    date: "August 22, 2023",
    notes: [
      "Enhanced lists on Company Dashboard to help with speed and performance.",
      "Inactive sites should not longer appear in OBS and HA.",
      "Inactive HA and OBS will not appear on view pages.",
      "Fixed bug where HA closeout and reminder email are sent at same time.",
      "Fixed bug where mitigator risk score was not updating.",
    ],
  },
  {
    versionNum: "0.2.9",
    date: "August 17, 2023",
    notes: [
      "Change PWA to include some of browser UI like refresh and back buttons.",
      "Fixed duplication of sites issue.",
      "Fixed HA letter grade score issues.",
      "Improvement and fixes to editing projects.",
    ],
  },
  {
    versionNum: "0.2.8",
    date: "August 15, 2023",
    notes: [
      "Fixed crew members not loading on edit HA.",
      "Fixed bugs with category section of ARM admin.",
      "Fixed OBS bug that allows you to proceed when editing in General section.",
      "Added way to edit a Crewmember's company.",
      "Fixed template associated to site owner company and not user company on HA.",
      "Fixed projects in project planning admin having empty site owners.",
    ],
  },
  {
    versionNum: "0.2.7",
    date: "August 14, 2023",
    notes: [
      "Updated unassociated risks error message.",
      "Alphabetized the associated actions on categories.",
      "If a user supervises a company, and that company is a contractor on sites owned by other companies, those sites are now returned for the user when making an observation, HA, or on the company/user admin dashboard.",
    ],
  },
  {
    versionNum: "0.2.6",
    date: "August 8, 2023",
    notes: [
      "Fixed bug for templates showing as company-wide on table.",
      "Fixed supervisors showing at pages where they were removed as supervisors from that company.",
      "Various other Airtable bug fixes.",
    ],
  },
  {
    versionNum: "0.2.5",
    date: "August 5, 2023",
    notes: [
      "Added companies to create and edit template, project template populating on HA, and contractor templates population on HA",
      "Added number of notifications sent/seen on observation table.",
    ],
  },
  {
    versionNum: "0.2.4",
    date: "July 21, 2023",
    notes: ["Fixes to AirTable bug reports."],
  },
  {
    versionNum: "0.2.3",
    date: "July 14, 2023",
    notes: [
      "Fixed feedback from 0.2.2.",
      "Fixed HA closing bug.",
      "Fixed mobile/desktop OBS/HA bug.",
      "Fixed user creation bug.",
    ],
  },
  {
    versionNum: "0.2.2",
    date: "July 13, 2023",
    notes: [
      "Added company logos to Observation, Audit, Incident PDFs.",
      "Fixed all items in 0.2.1 feedback document, listed below.",
      "EMAIL: Fixed link issue. Changed  subject of observation emails.",
      "OBS: Fixed error when saving edited OBS with removed images. Fixed manually unplanned forms do not show so.",
      "VIEW: All table filters are alphabetical. Table header sorting arrows work on all tables. Can sort by multiple submitters on OBS, and company for HA.",
      "AUDIT: Audit type added to PDF.",
      "INC: Fixed investigation bugs.",
      "ARM ADMIN: Sorted all associated risks/mitigators.",
      "SUP ADMIN: Sort is now by all items in relationship. Added site owner to display for project supervision.",
    ],
  },
  {
    versionNum: "0.2.1",
    date: "July 12, 2023",
    notes: [
      "Fixed 'Send to Email' buttons for all reports and stopped emails from sending on create/edit.",
      "OBS: Added solution to show actions selected by searching. Hid second search bar.",
      "OBS: Implemented filtering for users listed when creating Corrections notification.",
      "OBS: Further fixed the removal of ARM images. Now risk/mitigator images associated to removed action are removed, as well as mitigator images associated to reoved risks.",
      "OBS: Created ARMs are automatically selected.",
      "OBS: Fixed how Observations are listed as planned/unplanned",
      "VIEW OBS: Fixed filter crash.",
      "VIEW HA: Fixed site filter.",
      "AUDIT: Added user profile picture. Fixed penalty decimal places.",
      "INC: Fixed formatting of count of days on PDF. Fixed format of conjoined question labels.",
      "VIEW INC: Added filter by incident type. Filtered user/site options by already submitted items.",
      "USER ADMIN: Table header arrow sorting now works.",
      "SUP ADMIN: Added search bars to unadded items.",
      "ARM ADMIN: Fixed jumping around of risks. Fixed scoring issue of mitigators associated to risks and sorted them.",
      "PLANNING: Fixed issues with active/inactive sites. Fixed crewmember issues on Edit Site.",
      "COMPANY: Added popup for Observed Site plus button.",
      "USER: Fixed editing name in User Admin. Prevents changing email.",
      "ADMIN: Fixed various issues with updating users/supervision.",
    ],
  },
  {
    versionNum: "0.2.0",
    date: "June 30, 2023",
    notes: [
      "Emails should be working.",
      "DASHBOARD: Removed risk mitigation dial on mobile dashboard and replaced with greeting header to match desktop version and indicate what account is logged in.",
      "OBS: HAs are now filtered by submissions from the past day.",
      "OBS: The clear and save buttons are now moved to opposite sides of the screen to prevent button pressing errors.",
      "OBS: The action search bar clears after an action is selected.",
      "OBS: The correct users are now shown as selected when creating a corrections notification.",
      "OBS: Fixed the issue with multiple outcomes saving to it after editing.",
      "OBS: Added mitigator legend to PDF.",
      "OBS: View Images labels each image as action, risk, and mitigator and correction names are in yellow text. Also fixed how it looks on mobile.",
      "OBS: Now images associated with removed ARMs are removed.",
      "OBS: Created ARMs will automatically add.",
      "VIEW OBS: Fixed filters.",
      "HA: Clear button now clears name. Also fixed to show it on mobile.",
      "HA: Removed Emergency Response Info from PDF for now. Fixed formatting to remove second blank page.",
      "VIEW HA: Search is now by HA name and submitter username. Fixed site filter.",
      "AUDIT: Fixed to show username if user has no first name/last name. Also formatted potential citation to 2 decimal places.",
      "VIEW AUDIT: Added proper filtering to audit table.",
      "INC: Added toggle to date of death. Added counts of days to PDF.",
      "VIEW INC: Fixed site filter.",
      "USER ADMIN: Company list is now alphabetical. Users can be filtered by company. Added permission groups will now reload automatically.",
      "SUP ADMIN: New supervisors will now reload automatically. List of users when creating is now alphabetical.",
      "PERM ADMIN: Prevent saved permissions from moving order. Permission groups sorted in alphabetical order. Fixed permission group filter in User Admin table.",
      "ARM ADMIN: Major improvements for ARM edit sections. Sorted unselected risks on action page. Fixed crash. 'See Mitigators' now only shows ones part of ARM tree. ARM names can now have periods and other symbols.",
      "ARM ADMIN: Can now search through approvals and unscored arms to make it easier to find.",
      "PLANNING: Added site/project info in brackets and make searchable/filterable. Fixed inability to deactivate projects. Fixed inactive templates showing up in HAs.",
      "SITE PLANNING: Form will now close after crewmembers add themselves to prevent multiple submissions. Fixed inability to remove templates/crew from site.",
      "TEMPLATE PLANNING: Now added template actions will automatically reload to show on PDF.",
      "COMPANY: Fixed active/inactive company toggle on Company Dashboard. Fixed creating site error on Dashboard.",
      "USER SETTINGS: Fixed error when changing name or email.",
    ],
  },
  {
    versionNum: "0.1.18",
    date: "June 26, 2023",
    notes: [
      "Fixed various crashing issues.",
      "Fixed bug with all companies showing as child companies.",
    ],
  },
  {
    versionNum: "0.1.17",
    date: "June 21, 2023",
    notes: [
      "Adjusted formatting of Audit PDF",
      "Improved ARM approval process",
      "Fixed any errors when creating a company",
      "Increased limit of file uploads to 100MB for Incident Investigation and made files viewable from PDF",
      "Table will refresh when changing active status of an ARM",
      "Fixed error when adding users to a supervisor",
      "Fixed error when adding permissions to users",
      "Added correct start time to HA PDFs",
      "Fixed error when uploading audit csv if one already exists with the same name - it will prevent you from uploading it",
      "Audit can't be uploaded if one already exists with the same name",
    ],
  },
  {
    versionNum: "0.1.16",
    date: "April 28, 2023",
    notes: [
      "Added Files to the incident investigation.",
      "Added arm share abilities.",
      "Fixed various Airtable bugs.",
    ],
  },
  {
    versionNum: "0.1.15",
    date: "April 14, 2023",
    notes: ["Added Incident Investigation - still in progress."],
  },
  {
    versionNum: "0.1.14",
    date: "April 6, 2023",
    notes: [
      "Added Audit Management.",
      "Added Crew Member signup.",
      "Added site QA code.",
      "Added bulk upload Crew Members.",
      "Fixed various AirTable bugs.",
    ],
  },
  {
    versionNum: "0.1.13",
    date: "MARCH 17, 2023",
    notes: [
      "Added in permissions. Currently in progress with adding the permissions functionality through whole app.",
    ],
  },
  {
    versionNum: "0.1.12",
    date: "MARCH 15, 2023",
    notes: [
      "Various bugs are fixed, including: submit observation error, observation view error (due to submition without contractor), supervision updating error, observation templates not showing all actions, observation expected risks not showing properly, edit company error, and unable to edit HA error.",
    ],
  },
  {
    versionNum: "0.1.11",
    date: "MARCH 10, 2023",
    notes: [
      "Supervisor page has add new user, searching functionality, and risk mitigated.",
      "Crew member edit dialog completed.",
      "User dialog supervision tab completed.",
      "Design updates to arms.",
      "Added production database. You can now use your normal username and password.",
      "Various bug fixes.",
    ],
  },
  {
    versionNum: "0.1.10",
    date: "MARCH 3, 2023",
    notes: [
      "All file types can be now added to projects.",
      "Removed category and company selection from creating ARMs on observation form.",
      "Added tables to view a user's observations, audits, and HAs when viewing their profile through company dashboard or User Administration.",
      "Fixed photos and added Risk Corrected score on Observation PDFs.",
      "Began incorporating the role-based access system for viewing companies, sites, projects, users and ARMs for companies that are not administrative organizations.",
      "Added creating and editing templates.",
      "Added Supervisor Admin page. Edit ability still in progress.",
    ],
  },
  {
    versionNum: "0.1.9",
    date: "FEB 24, 2023",
    notes: [
      "Progress made on view/create/edit on company dashboard cards for sites, projects, templates, supervisors, contractors, users and crewmembers as well as planning admin pages.",
      "Added files to projects.",
      "Added toggle to show mitigators in template PDF.",
      "Updated risk severity on create/edit audits.",
    ],
  },
];
