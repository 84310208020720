import React from "react";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";

import { useMutation, useLazyQuery } from "@apollo/client";

import { CREATE_SITE } from "../../../../graphql/mutations/admin/planning-site";
import { GET_SITES_ADMIN } from "../../../../graphql/queries/admin/planning-site";
import removeDuplicates from "../../../../utils/removeDuplicates";
import sortByKeys from "../../../../utils/sortByKeys";
import { states } from "../../../../utils/states";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { StyledTab, StyledTabs } from "../../../CustomComponents/Tabs";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_OSP } from "../../Company/companyAdminQueries";
import {
  UserAccordion,
  ContractorAccordion,
  CrewAccordion,
  TemplateAccordion,
} from "./Table/SiteAccordions";

export default function CreateSite({ open, handleClose, company, data }) {
  const [getCompanyContractors, { data: companyContractors }] = useLazyQuery(
    COMPANY_ADMIN_OSP,
    { fetchPolicy: "no-cache" }
  );
  const [getParentContractors, { data: parentContractors }] = useLazyQuery(
    COMPANY_ADMIN_OSP,
    { fetchPolicy: "no-cache" }
  );

  const [contractors, setContractors] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      if (company) {
        getCompanyContractors({
          variables: { id: Number(company.id) },
        });
        if (company.parent) {
          getParentContractors({
            variables: { id: Number(company.parent.id) },
          });
        }
      }
    }
  }, [open]);

  React.useEffect(() => {
    const removeDuplicateContractors = (contractors) => {
      const uniqueIds = [];
      const filtered = contractors.filter((c) => {
        const isDuplicate = uniqueIds.includes(c.contractor.id);
        if (!isDuplicate) {
          uniqueIds.push(c.contractor.id);
          return true;
        }
        return false;
      });
      return filtered;
    };

    if (company) {
      let contractors = [];
      if (companyContractors) {
        companyContractors.ownerSiteProjects.forEach((d) => {
          contractors = [
            ...contractors,
            ...d.ownerSiteProjectContractor.map((ospc) => ({
              owner: d.owner,
              ...ospc,
            })),
          ];
        });
      }

      if (parentContractors) {
        parentContractors.ownerSiteProjects.forEach((d) => {
          contractors = [
            ...contractors,
            ...d.ownerSiteProjectContractor.map((ospc) => ({
              owner: d.owner,
              ...ospc,
            })),
          ];
        });
      }

      contractors = removeDuplicateContractors(contractors).filter(
        (c) => c.contractor.id !== company.id
      );
      setContractors(contractors.map((c) => c.contractor));
    }
  }, [companyContractors, parentContractors]);

  const [createSite, { loading: loadingCreate }] = useMutation(CREATE_SITE, {
    onCompleted() {
      handleClose();
      onClear();
      onCompletedFunc("Site has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_SITES_ADMIN },
      company && {
        query: COMPANY_ADMIN_OSP,
        variables: { id: Number(company.id) },
      },
    ],
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [owner, setOwner] = React.useState({ name: "" });
  const [name, setName] = React.useState("");
  const [addr1, setAddr1] = React.useState("");
  const [addr2, setAddr2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [note, setNote] = React.useState("");

  const [addedUsers, setAddedUsers] = React.useState([]);
  const [supervisors, setSupervisors] = React.useState([]);

  const [addedContractors, setAddedContractors] = React.useState([]);

  const [addedCrew, setAddedCrew] = React.useState([]);

  const [addedTemplates, setAddedTemplates] = React.useState([]);

  const onCreate = () => {
    if (name === "") {
      onErrorFunc("Include a name for this site.");
    } else if (state === "") {
      onErrorFunc("Include the state for this site.");
    } else if (!company && owner.name === "") {
      onErrorFunc("Include a site owner.");
    } else {
      createSite({
        variables: {
          addrCity: city,
          addrLine1: addr1,
          addrLine2: addr2,
          addrState: state,
          addrZip: zip,
          company: company ? Number(company.id) : Number(owner.id),
          name: name,
          note,
          associatedUsers: addedUsers.map((u) => Number(u.id)),
          supervisors,
          associatedTemplates: addedTemplates.map((t) => Number(t.id)),
          associatedCrewMembers: addedCrew.map((c) => Number(c.id)),
          associatedContractors: addedContractors.map((c) => Number(c.id)),
        },
      });
    }
  };

  const onCancel = () => {
    handleClose();
    onClear();
  };

  const onClear = () => {
    setOwner({ name: "" });
    setName("");
    setAddr1("");
    setAddr2("");
    setCity("");
    setState("");
    setZip("");
    setAddedUsers([]);
    setSupervisors([]);
    setAddedContractors([]);
    setAddedCrew([]);
    setAddedTemplates([]);
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE SITE.
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          {!company && (
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="select-site-owner"
                options={sortByKeys(data.owners.companies, "name")}
                value={owner}
                onChange={(event, value) => setOwner(value)}
                getOptionLabel={(option) => option.name}
                disableClearable
                loading={data.loadingOwners}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    style={{ color: "#fff" }}
                    variant="standard"
                    label={"Site Owner:"}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Site Name:"
              variant="standard"
              style={{ width: "100%" }}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 1:"
              variant="standard"
              style={{ width: "100%" }}
              value={addr1}
              onChange={(event) => setAddr1(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 2:"
              variant="standard"
              style={{ width: "100%" }}
              value={addr2}
              onChange={(event) => setAddr2(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="City:"
              variant="standard"
              style={{ width: "100%" }}
              value={city}
              onChange={(event) => setCity(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-state"
              options={states}
              value={state}
              onChange={(event, value) => setState(value)}
              disableClearable
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"State:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Zipcode:"
              variant="standard"
              style={{ width: "100%" }}
              value={zip}
              onChange={(event) => setZip(event.target.value)}
              type="number"
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Note:"
              variant="standard"
              style={{ width: "100%" }}
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </Grid>
        </Grid>
        <Paper>
          <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
            <StyledTab label="USERS" />
            <StyledTab label="CONTRACTORS" />
            <StyledTab label="CREWMEMBERS" />
            <StyledTab label="TEMPLATES" />
          </StyledTabs>

          <UserAccordion
            value={value}
            handleChange={handleChange}
            users={data.users}
            loading={data.loadingUsers}
            siteUsers={[]}
            setSiteUsers={() => {}}
            setRemovedUsers={() => {}}
            addedUsers={addedUsers}
            setAddedUsers={setAddedUsers}
            supervisors={supervisors}
            setSupervisors={setSupervisors}
          />
          <ContractorAccordion
            value={value}
            handleChange={handleChange}
            contractors={{
              companies: removeDuplicates(
                [...data.companies.companies, ...contractors],
                "id"
              ),
            }}
            loading={data.loadingCompanies}
            siteContractors={[]}
            setSiteContractors={() => {}}
            setRemovedContractors={() => {}}
            addedContractors={addedContractors}
            setAddedContractors={setAddedContractors}
          />
          <CrewAccordion
            value={value}
            handleChange={handleChange}
            crew={data.crew}
            loading={data.loadingCrew}
            siteCrew={[]}
            setSiteCrew={() => {}}
            addedCrew={addedCrew}
            setAddedCrew={setAddedCrew}
            setRemovedCrew={() => {}}
          />
          <TemplateAccordion
            value={value}
            handleChange={handleChange}
            templates={data.templates}
            loading={data.loadingTemplates}
            siteTemplates={[]}
            setSiteTemplates={() => {}}
            addedTemplates={addedTemplates}
            setAddedTemplates={setAddedTemplates}
            setRemovedTemplates={() => {}}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onCreate}
          loading={loadingCreate}
        >
          CREATE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
