import React from "react";

import { Check } from "@mui/icons-material";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  Avatar,
  Grid,
  Typography,
  Tooltip,
  LinearProgress,
  Divider,
  IconButton,
} from "@mui/material";

import removeDuplicates from "../../../../utils/removeDuplicates";
import sortByKeys from "../../../../utils/sortByKeys";
import { defaultAvatar } from "../../../CustomStyles/avatar";
import EditDialogUser from "../../Company/Dashboard/Carousel/Edit/EditUserDialog";

export default function Card({ isPinned, setPinnedUsers, d }) {
  // Local State
  const [userSup, setUserSup] = React.useState([]);
  const [projectSup, setProjectSup] = React.useState([]);
  const [siteSup, setSiteSup] = React.useState([]);
  const [compSup, setCompSup] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (d && d.supervise.length !== 0) {
      // Filter supervision by project, site, user, and company(owner)
      const users = [];
      const projects = [];
      const sites = [];
      const companies = [];
      // Loop through and push to arrays
      d.supervise.map((s) => {
        if (
          s.ownerSiteProject === null &&
          s.supervisee !== null &&
          s.isActive
        ) {
          users.push(s);
        } else if (
          s.ownerSiteProject !== null &&
          s.ownerSiteProject.project !== null &&
          s.isActive
        ) {
          projects.push(s);
        } else if (
          s.ownerSiteProject !== null &&
          s.ownerSiteProject.project === null &&
          s.ownerSiteProject.site !== null &&
          s.isActive
        ) {
          sites.push(s);
        } else {
          if (s.isActive) companies.push(s);
        }
      });
      setProjectSup(removeDuplicates(projects, "ownerSiteProject", "id"));
      setSiteSup(removeDuplicates(sites, "ownerSiteProject", "id"));
      setCompSup(removeDuplicates(companies, "ownerSiteProject", "id"));
      setUserSup(users);
    }
  }, [d]);

  const renderCheckTypography = (text, key) => {
    return (
      <Grid
        container
        key={key}
        item
        alignItems="center"
        xs={12}
        sx={{
          padding: "10px 20px",
          color: "#fff",
        }}
      >
        <Grid item xs={2}>
          <IconButton style={{ marginRight: "10px", color: "#fff" }}>
            <Check />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography>{text}</Typography>
        </Grid>
      </Grid>
    );
  };

  // Check which sections is selected and display
  const renderEdits = () => {
    switch (selected) {
      case "Companies":
        return compSup.map((co) => {
          return renderCheckTypography(
            co.ownerSiteProject?.owner.name,
            `companies ${co.id}`
          );
        });
      case "Sites":
        return siteSup.map((co) => {
          return renderCheckTypography(
            `${co.ownerSiteProject?.site.name} [${co.ownerSiteProject?.owner.name}]`,
            `sites ${co.id}`
          );
        });
      case "Projects":
        return projectSup.map((co) => {
          return renderCheckTypography(
            `${co.ownerSiteProject?.project.name} [${co.ownerSiteProject?.site.name}]`,
            `projects ${co.id}`
          );
        });
      case "Users":
        return sortByKeys(userSup, "supervisee.username").map((co) => {
          const text =
            co.supervisee && co.supervisee.firstName !== ""
              ? `${co.supervisee.firstName} ${co.supervisee.lastName}`
              : co.supervisee.username;
          return renderCheckTypography(text, `users ${co.id}`);
        });
      default:
        return null;
    }
  };

  // CSS for selected Sup tab
  const isSelected = (value) => {
    if (value === selected) {
      return { color: "rgb(255, 183, 0)", cursor: "pointer", margin: "auto" };
    } else {
      return { cursor: "pointer", margin: "auto" };
    }
  };

  // Set state of pinned users
  const onPin = () => {
    // If already pinned remove from pinned users
    if (isPinned) {
      setPinnedUsers((prev) => [...prev.filter((user) => user.id !== d.id)]);
      // If not pinned, add to pinned users
    } else {
      setPinnedUsers((prev) => [...prev, d]);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ color: "#fff" }}
    >
      {/* Profile Pic  */}

      <Grid
        item
        xs={3}
        alignItems="center"
        justifyContent="center"
        sx={{ padding: "20px", color: "#fff" }}
      >
        <PushPinIcon
          sx={
            isPinned
              ? { color: "yellow0.main", cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={onPin}
        />
      </Grid>
      <Grid
        item
        xs={6}
        alignItems="center"
        justifyContent="center"
        onClick={() => setSelected("")}
        sx={{ padding: "20px 20px 0px 20px", color: "#fff" }}
      >
        <Avatar
          alt={`${d.firstName} ${d.lastName}`}
          src={d.image}
          sx={{
            ...defaultAvatar,
            width: "100px",
            margin: "auto",
            height: "100px",
            fontSize: "2.5rem",
          }}
        />
      </Grid>
      <Grid
        item
        xs={3}
        alignItems="center"
        justifyContent="center"
        onClick={() => setSelected("")}
        sx={{ padding: "20px", color: "#fff" }}
      ></Grid>
      <Grid
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        onClick={() => setSelected("")}
        sx={{ padding: "0px 20px 20px 20px", color: "#fff" }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            textAlign: "center",
            cursor: "pointer",
            margin: "10px 0px 0px 0px",
          }}
        >
          {d.firstName !== "" ? `${d.firstName} ${d.lastName}` : d.username}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.75rem",
            textAlign: "center",
          }}
        >
          {d.company?.name}
        </Typography>
        <Typography
          sx={{
            fontSize: ".75rem",
            textAlign: "center",
            cursor: "pointer",
            mt: 1,
            color: "yellow0.main",
          }}
          onClick={() => setOpen(true)}
        >
          EDIT
        </Typography>
        {open && (
          <EditDialogUser setOpen={setOpen} open={open} d={d} tabIndex={3} />
        )}
      </Grid>

      {/* Supervision */}

      <Grid item xs={12} alignItems="center" justifyContent="center">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          item
          xs={12}
          sx={{ padding: "0px 20px 20px 20px", color: "#fff" }}
        >
          <Grid
            item
            sx={isSelected("Companies")}
            onClick={() =>
              selected === "Companies"
                ? setSelected("")
                : setSelected("Companies")
            }
          >
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              {compSup.length}
            </Typography>
            <Tooltip title={"Companies"}>
              <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                Companies
              </Typography>
            </Tooltip>
          </Grid>
          <Grid
            item
            sx={isSelected("Sites")}
            onClick={() =>
              selected === "Sites" ? setSelected("") : setSelected("Sites")
            }
          >
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              {siteSup.length}
            </Typography>
            <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
              Sites
            </Typography>
          </Grid>
          <Grid
            item
            sx={isSelected("Projects")}
            onClick={() =>
              selected === "Projects"
                ? setSelected("")
                : setSelected("Projects")
            }
          >
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              {projectSup.length}
            </Typography>
            <Tooltip title={"Projects"}>
              <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
                Projects
              </Typography>
            </Tooltip>
          </Grid>
          <Grid
            item
            sx={isSelected("Users")}
            onClick={() =>
              selected === "Users" ? setSelected("") : setSelected("Users")
            }
          >
            <Typography sx={{ fontSize: "1.25rem", fontWeight: "bold" }}>
              {userSup.length}
            </Typography>
            <Typography sx={{ fontSize: ".75rem", lineHeight: 0.9 }} noWrap>
              Users
            </Typography>
          </Grid>
        </Grid>
        <Grid
          style={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {renderEdits()}
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: "#BDBDBD" }} />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            padding: "20px 40px",
          }}
        >
          <Typography sx={{ fontSize: ".75rem" }}>
            Risk Mitigated:{" "}
            {d.riskMitigated ? Number(d.riskMitigated).toFixed(2) : 0}%
          </Typography>
          <LinearProgress
            variant="determinate"
            value={d.riskMitigated ? d.riskMitigated : 0}
            color="yellow1"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
