import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { useMutation, useLazyQuery, useQuery } from "@apollo/client";

import {
  BULK_APPROVE_COMPANY_ARM,
  MUTATE_ACTION,
  MUTATE_MITIGATOR,
  MUTATE_RISK,
} from "../../../graphql/mutations/admin/arm";
import { GET_COMPANY_ARMS_PAGINATED_ADMIN } from "../../../graphql/queries/admin/arm";
import { GET_COMPANY } from "../../../graphql/queries/admin/company";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import SearchField from "../../CustomComponents/SearchField";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

function ApprovalItem({
  item,
  onApprove,
  onDeny,
  mutateAction,
  mutateRisk,
  mutateMitigator,
  allItems,
  approved,
  denied,
}) {
  const [actionName, setActionName] = React.useState(item.arm.a.name);
  const [riskName, setRiskName] = React.useState(item.arm.r.name);
  const [mitName, setMitName] = React.useState(item.arm.m.name);
  const [open, setOpen] = React.useState(false);

  // if action and risk is already approved, can't edit their names
  const action = allItems.find(
    (arm) => arm.arm.a.id === item.arm.a.id && !arm.arm.r?.id
  );
  const risk = allItems.find(
    (arm) =>
      arm.arm.a?.id === item.arm.a?.id &&
      arm.arm.r?.id === item.arm.r?.id &&
      !arm.arm.m?.id
  );

  const editAction = action ? !action.isApproved : false;
  const editRisk = risk ? !risk.isApproved : false;

  const onSave = () => {
    if (editAction && actionName !== item.arm.a.name) {
      mutateAction({
        variables: {
          id: Number(action.arm.a.id),
          name: actionName,
          isActive: true,
        },
      });
    }
    if (editRisk && riskName !== item.arm.r.name) {
      mutateRisk({
        variables: {
          id: Number(risk.arm.r.id),
          name: riskName,
          isActive: true,
        },
      });
    }
    if (mitName !== item.arm.m.name) {
      mutateMitigator({
        variables: { id: Number(item.arm.m.id), name: mitName, isActive: true },
      });
    }
    setOpen(false);
    onApprove(item);
  };

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      style={{
        margin: "10px 0px",
        borderBottom: "1px solid grey",
        height: "100%",
      }}
    >
      <Grid item xs={2} style={{ padding: "10px" }}>
        <Typography>
          {item.user?.username} [{item.company.name}]
        </Typography>
      </Grid>

      <Grid item xs={2} style={{ padding: "10px" }}>
        <Typography sx={{ color: editAction ? "yellow0.main" : "white" }}>
          {item.arm.a.name}
        </Typography>
      </Grid>

      <Grid item xs={3} style={{ padding: "10px" }}>
        <Typography sx={{ color: editRisk ? "yellow0.main" : "white" }}>
          {item.arm.r.name}
        </Typography>
      </Grid>

      <Grid item xs={3} style={{ padding: "10px" }}>
        <Typography sx={{ color: "yellow0.main" }}>
          {item.arm.m.name}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Tooltip title="Approve">
          <IconButton onClick={() => onApprove(item)}>
            <Check
              sx={{
                fill: approved.includes(Number(item.id)) ? "#FFB700" : "white",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Deny">
          <IconButton
            onClick={() => {
              onDeny(item);
            }}
          >
            <Close
              sx={{
                fill: denied.includes(Number(item.id)) ? "#FFB700" : "white",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton onClick={() => setOpen(true)}>
            <Edit sx={{ fill: "white" }} />
          </IconButton>
        </Tooltip>
      </Grid>
      {open && (
        <CustomDialog
          open={open}
          fullWidth
          maxWidth="md"
          onClose={() => setOpen(false)}
        >
          <DialogTitle style={{ ...pageTitleStyles }}>EDIT ARM.</DialogTitle>
          <DialogContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  variant="standard"
                  value={actionName}
                  label="Action Name:"
                  onChange={(event) => setActionName(event.target.value)}
                  disabled={!editAction}
                  fullWidth
                  multiline
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              {item.arm.r.name && (
                <Grid item xs={12}>
                  <CustomTextField
                    variant="standard"
                    value={riskName}
                    label="Risk Name:"
                    onChange={(event) => setRiskName(event.target.value)}
                    disabled={!editRisk}
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              )}
              {item.arm.m.name && (
                <Grid item xs={12}>
                  <CustomTextField
                    variant="standard"
                    value={mitName}
                    label="Mitigator Name:"
                    onChange={(event) => setMitName(event.target.value)}
                    fullWidth
                    multiline
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="yellow0"
              sx={{ mr: 1 }}
              onClick={onSave}
            >
              SAVE
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              CANCEL
            </Button>
          </DialogActions>
        </CustomDialog>
      )}
    </Grid>
  );
}

export default function Approvals() {
  const [approved, setApproved] = React.useState([]);
  const [denied, setDenied] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState(null);
  const { companyId } = useParams();
  const navigate = useNavigate();

  const { data: currentUser } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });

  const [
    getCompanyARMsPagination,
    { data: dataCompArms, loading: loadingArms, refetch },
  ] = useLazyQuery(GET_COMPANY_ARMS_PAGINATED_ADMIN, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const [getCompany, { data: company }] = useLazyQuery(GET_COMPANY, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    getCompanyARMsPagination({
      variables: {
        isApproved: false,
        isActive: true,
        first: 200,
        after: null,
        fName: search,
      },
    });
  }, []);

  const getCompanyId = () => {
    if (companyId) return Number(companyId);
    if (currentUser?.currentUser?.company)
      return Number(currentUser.currentUser.company.id);
    return null;
  };

  React.useEffect(() => {
    const idToUse = getCompanyId();

    if (idToUse) {
      getCompany({
        variables: {
          id: idToUse,
        },
      });
    }
  }, [currentUser, companyId]);

  const fetchMore = (obj) => {
    if (obj.next) {
      const idToUse = getCompanyId();

      const input = {
        after: obj.after,
        first: obj.first,
        fName: search,
        company_Id: Number(idToUse),
        isApproved: false,
        isActive: true,
      };

      refetch(input);
    }
  };

  const requery = (search = "") => {
    const idToUse = getCompanyId();

    const input = {
      after: null,
      first: 200,
      fName: search,
      company_Id: Number(idToUse),
      isApproved: false,
      isActive: true,
    };

    refetch(input);
  };

  React.useEffect(() => {
    if (dataCompArms) {
      setData(dataCompArms);
    }
  }, [dataCompArms]);

  const [approveARM, { loading: loadingSave }] = useMutation(
    BULK_APPROVE_COMPANY_ARM,
    {
      onCompleted() {
        requery();
        onCompletedFunc("Your changes have been saved");
        setApproved([]);
        setDenied([]);
      },
      onError(error) {
        onErrorFunc(error);
      },
    }
  );
  const [mutateAction] = useMutation(MUTATE_ACTION, {
    onCompleted() {
      requery();
      onCompletedFunc("Action has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const [mutateRisk] = useMutation(MUTATE_RISK, {
    onCompleted() {
      requery();
      onCompletedFunc("Risk has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });
  const [mutateMitigator] = useMutation(MUTATE_MITIGATOR, {
    onCompleted() {
      requery();
      onCompletedFunc("Mitigator has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  const onApprove = (item) => {
    setDenied((prev) => prev.filter((p) => p !== Number(item.id)));
    if (!approved.includes(Number(item.id))) {
      setApproved((prev) => [...prev, Number(item.id)]);
    } else {
      setApproved((prev) => prev.filter((p) => p !== Number(item.id)));
    }
  };

  const onDeny = (item) => {
    setApproved((prev) => prev.filter((p) => p !== Number(item.id)));
    if (!denied.includes(Number(item.id))) {
      setDenied((prev) => [...prev, Number(item.id)]);
    } else {
      setDenied((prev) => prev.filter((p) => p !== Number(item.id)));
    }
  };

  const cancelFn = () => {
    navigate(-1);
    setApproved([]);
    setDenied([]);
    setSearch("");
  };

  const saveApprovals = () => {
    approveARM({
      variables: {
        compArmsToApprove: approved,
        compArmsToDeny: denied,
      },
    });
  };

  React.useEffect(() => {
    requery(search);
  }, [search]);

  const getRow = (d) => {
    return (
      <ApprovalItem
        key={d.carmId}
        item={d}
        onApprove={onApprove}
        onDeny={onDeny}
        mutateAction={mutateAction}
        mutateRisk={mutateRisk}
        mutateMitigator={mutateMitigator}
        allItems={data.companyArmsPaginated.edges.map((e) => e.node)}
        approved={approved}
        denied={denied}
      />
    );
  };

  return (
    <div>
      <DialogTitle style={{ ...pageTitleStyles }}>
        APPROVALS{company ? `: ${company.company.name}` : "."}
      </DialogTitle>
      <DialogContent style={{ color: "white", overflowX: "auto" }}>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Total Unapproved ARMs:{" "}
          {data ? data.companyArmsPaginated.totalCount : 0}
        </Typography>
        <div style={{ margin: "20px 0px" }}>
          <SearchField value={search} setValue={setSearch} />
        </div>
        <Grid container style={{ minWidth: "800px" }}>
          <Grid item xs={2}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderBottom: "1px solid grey",
              }}
            >
              Submitted By
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderBottom: "1px solid grey",
              }}
            >
              Action
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderBottom: "1px solid grey",
              }}
            >
              Risk
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderBottom: "1px solid grey",
              }}
            >
              Mitigator
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderBottom: "1px solid grey",
              }}
            >
              Approval Status
            </Typography>
          </Grid>
          <Virtuoso
            style={{ height: "calc(100vh - 350px)", width: "100%" }}
            data={data?.companyArmsPaginated.edges.map((a) => a.node)}
            itemContent={(index, d) => getRow(d)}
            overscan={200}
            endReached={() => {
              fetchMore({
                after:
                  data?.companyArmsPaginated.edges[
                    data?.companyArmsPaginated.edges.length - 1
                  ]?.cursor,
                first: data?.companyArmsPaginated.edges.length + 200,
                next: data?.companyArmsPaginated.pageInfo.hasNextPage,
              });
            }}
          />
        </Grid>
      </DialogContent>
      {loadingArms ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : null}
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={saveApprovals}
          loading={loadingSave}
        >
          SAVE
        </LoadingButton>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={cancelFn}
        >
          CANCEL
        </Button>
      </DialogActions>
    </div>
  );
}
