import React from "react";
import SignaturePad from "react-signature-canvas";

import { Grid, Button, Dialog } from "@mui/material";

import { useStyles } from "./styles";

export default function SignatureView({
  signature,
  setShowSignature,
  replaceSignature,
  showSignature,
}) {
  const [sigImage, setSigImage] = React.useState(
    signature !== "" ? signature : null
  );
  const classes = useStyles()();
  const sigPad = React.useRef();

  const clear = () => {
    sigPad.current.clear();
  };

  const save = () => {
    const newSignature = sigPad.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setSigImage(newSignature);
    replaceSignature(newSignature);
    setShowSignature(false);
  };

  return (
    <Dialog onClose={() => setShowSignature(false)} open={showSignature}>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} container justifyContent="center">
          {sigImage ? (
            <img
              src={sigImage}
              style={{ maxHeight: "150px", width: "auto" }}
              alt="Signature"
            />
          ) : (
            <div
              style={{
                position: "relative",
                width: "400px",
                height: "200px",
                marginBottom: "20px",
              }}
            >
              <SignaturePad
                canvasProps={{ className: classes.sigPad }}
                ref={(ref) => {
                  sigPad.current = ref;
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          {sigImage ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSigImage(null)}
            >
              RESET SIGNATURE
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="yellow0"
                sx={{ mr: 1 }}
                onClick={save}
              >
                SAVE
              </Button>
              <Button variant="contained" color="primary" onClick={clear}>
                CLEAR
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
