import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import ShowInactiveFilter from "../../../CustomComponents/ShowInactiveFilter";
import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
} from "../../../CustomStyles/searchBar";

export default function ReusableTableToolbar({
  numSelected,
  title,
  onSearchChange,
  onInactiveChange,
  inactive,
}) {
  const [text, setText] = React.useState("");

  return (
    <Toolbar
      style={{ padding: 0 }}
      sx={{
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontWeight: "bolder",
          fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI'",
        }}
        id="tableTitle"
      >
        {title ? title.toUpperCase() : ""}
      </Typography>
      <Tooltip title="Filter">
        <ShowInactiveFilter
          showInActive={inactive}
          setShowInActive={onInactiveChange}
        />
      </Tooltip>
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Press Enter to Search"
            inputProps={{
              "aria-label": "search",
            }}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearchChange({ target: { value: text } });
              }
            }}
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}
