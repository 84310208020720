import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Helvetica",
  },
  header: { fontSize: "12px", fontFamily: "Helvetica-Bold" },
  question: { width: "185px" },
  text: { padding: "20px", fontSize: "10px" },
});

export default styles;
