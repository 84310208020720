import { gql } from "@apollo/client";

export const GET_SUPERVISORS = gql`
  query supervisors {
    users(isActive: true) {
      username
      firstName
      lastName
      id
      lastLogin
      email
      image
      riskMitigated
      company {
        name
      }
      supervise {
        id
        isActive
        supervisee {
          id
          firstName
          lastName
          username
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`;
