import React from "react";

import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import PropTypes from "prop-types";

const getNumDays = (startDate, endDate) => {
  const d1 = moment(startDate);
  const d2 = moment(endDate);
  const totalDays = d2.diff(d1, "days");

  let weekends = 0;
  for (let m = moment(d1); m.isBefore(d2); m.add(1, "days")) {
    if (m.day() === 0 || m.day() === 6) {
      weekends++;
    }
  }

  const countNoWeekend = totalDays - weekends;
  return { countAll: totalDays, countNoWeekend };
};

const questionStyle = {};
const textStyle = {
  fontSize: "14px",
  paddingVertical: "10px",
};

const getMultiDateRange = (question) => {
  const { countAll, countNoWeekend } = question.dateRanges.reduce(
    (acc, r) => {
      const { countAll: all, countNoWeekend: noWeekend } = getNumDays(
        r.startDate,
        r.endDate
      );
      return {
        countAll: acc.countAll + all,
        countNoWeekend: acc.countNoWeekend + noWeekend,
      };
    },
    { countAll: 0, countNoWeekend: 0 }
  );

  return (
    <View style={questionStyle}>
      {question.dateRanges.map((dr, index) => {
        return (
          <View
            key={`${dr.startDate} to ${dr.endDate}`}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            {index > 0 ? <Text>{"\n"}</Text> : null}
            <Text style={[textStyle, { width: "100%" }]}>
              {`${moment(dr.startDate).format("MM/DD/YYYY")} to ${moment(
                dr.endDate
              ).format("MM/DD/YYYY")}`}
              {"\n"}
            </Text>
          </View>
        );
      })}
      <Text>{"\n"}</Text>
      <Text style={textStyle}>
        Number of days excluding weekends: {countNoWeekend} {"\n"}
      </Text>
      <Text style={textStyle}>
        Number of days including weekends: {countAll} {"\n"}
      </Text>
    </View>
  );
};

export default function Question({ question }) {
  switch (question.incidentQuestion.type) {
    case "TextInput":
    case "PhoneNumber":
      return (
        <View style={questionStyle}>
          <Text style={textStyle}>{question.textInput}</Text>
        </View>
      );
    case "TextBox":
      return (
        <View style={questionStyle}>
          <Text style={textStyle}>{question.textBox}</Text>
        </View>
      );
    case "MultipleChoice":
      return (
        <View style={questionStyle}>
          <Text style={textStyle}>
            {question.options.length > 0 ? question.options[0].option.name : ""}
          </Text>
        </View>
      );
    case "Boolean":
      return (
        <View style={questionStyle}>
          <Text style={textStyle}>{question.boolean ? "Yes" : "No"}</Text>
        </View>
      );
    case "Checkbox":
      return (
        <View style={questionStyle}>
          <Text style={textStyle}>
            {question.options.map((o) => o.option.name).join(", ")}
          </Text>
        </View>
      );
    case "DateField":
      return (
        <View style={questionStyle}>
          <Text style={textStyle}>{question.date}</Text>
        </View>
      );
    case "MultiDateRange":
      return getMultiDateRange(question);
    default:
      return (
        <View>
          <Text> </Text>
        </View>
      );
  }
}

Question.propTypes = {
  question: PropTypes.object,
};
