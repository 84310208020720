import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import ExifReader from "exifreader";
import PropTypes from "prop-types";

import checkbox from "../../../../images/checkbox.png";
import { styles } from "./styles";

export const ObsImage = ({ img }) => {
  const [exif, setExif] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(img.mediaFile.file);
      const blob = await res.blob();
      const arrBuff = await blob.arrayBuffer();
      const tags = ExifReader.load(arrBuff);
      setExif(tags);
    }
    fetchData();
  }, []);

  let style;
  const rotVal = exif.Orientation?.value;
  if (rotVal == 1 || rotVal == undefined) {
    style = { width: "175px" };
  } else if (rotVal == 3) {
    style = { width: "175px", transform: "rotate(180deg)" };
  } else if (rotVal == 6) {
    style = { width: "175px", transform: "rotate(90deg)" };
  } else if (rotVal == 8) {
    style = { width: "175px", transform: "rotate(270deg)" };
  }

  return (
    <Image
      key={img.id}
      style={{ ...style, marginTop: "10px" }}
      src={img.mediaFile.file}
    />
  );
};

ObsImage.propTypes = {
  img: PropTypes.object,
};

export default function ARMSection({ observation, arms }) {
  const unique = (arr) =>
    arr.reduce((results, item) => {
      if (
        item.isActive &&
        !results.some(
          (i) =>
            i.arm.a.name === item.arm.a.name && i.arm.a.id === item.arm.a.id
        )
      ) {
        results.push(item);
      }
      return results;
    }, []);

  const actions = unique(
    observation.observationArms.filter(
      (a) => a.isActive && !a.arm.r && !a.arm.m
    )
  );

  const findRisks = (a) => {
    const allRisks = observation.observationArms.filter(
      (oa) =>
        oa.arm.a.name === a.name &&
        oa.arm.a.id === a.id &&
        oa.arm.r &&
        !oa.arm.m
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (
          !results.some(
            (i) =>
              i.arm.r &&
              item.arm.r &&
              i.arm.r.name === item.arm.r.name &&
              i.arm.r.id === item.arm.r.id
          )
        ) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allRisks);
  };

  const findMitigators = (ar) => {
    const allMitigators = observation.observationArms.filter(
      (oa) =>
        oa.arm.r &&
        oa.arm.r.name === ar.r.name &&
        oa.arm.a.name === ar.a.name &&
        oa.isActive === true
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (item.arm.m !== null) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allMitigators);
  };

  const findUnselectedMitigators = (ar) => {
    const allMitigators = arms
      ? arms
          .filter(
            (arm) =>
              arm.arm.isActive &&
              arm.arm.r.name === ar.r.name &&
              arm.arm.a.name === ar.a.name
          )
          .map((item) => item.arm)
      : [];
    return allMitigators.filter(
      (mit) =>
        observation.observationArms
          .filter((a) => a.isActive)
          .findIndex(
            (obsArm) =>
              obsArm.arm.r &&
              mit.r &&
              obsArm.arm.r.name === mit.r.name &&
              obsArm.arm.m?.name === mit.m.name
          ) < 0
    );
  };

  const checkCorrection = (m) => {
    const corrections = observation.observationArms.filter(
      (a) => a.isCorrection
    );
    return corrections.findIndex((c) => Number(c.arm.id) === Number(m.id)) >= 0;
  };

  const checkRootCauses = (m) => {
    const rootCauses = observation.observationArms.filter((a) => a.isRootCause);
    const mitId = m.arm ? m.arm.id : m.id;
    return rootCauses.findIndex((c) => Number(c.arm.id) === Number(mitId)) >= 0;
  };

  const getCorrection = (m) => {
    const corrections = observation.observationArms.filter(
      (a) => a.isCorrection
    );
    return corrections.filter((c) => Number(c.arm.id) === Number(m.id));
  };

  const findExpected = (risk) => {
    if (risk.arm.m === null) {
      return risk.arm.isExpected;
    }
    const index = observation.observationArms.findIndex(
      (arm) => arm.arm.r && arm.arm.r.id === risk.arm.r.id && arm.arm.m === null
    );
    if (index >= 0) {
      return observation.observationArms[index].arm.isExpected;
    } else return false;
  };

  const getLastMit = (risk, index) => {
    return (
      findMitigators(risk).length - 1 === index &&
      findUnselectedMitigators(risk).length === 0
    );
  };

  const getLastUnselectedMit = (risk, index) => {
    return findUnselectedMitigators(risk).length - 1 === index;
  };

  const getActionUnplanned = (actionId) => {
    if (
      observation.ha?.haArms?.findIndex((harm) => harm.arm.a.id == actionId) < 0
    )
      return "[Unplanned]";
    return "";
  };

  return (
    <Page>
      <View style={styles.page}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <View style={styles.armSectionHeader}>
            <Text
              style={{
                padding: "20px",
                fontSize: "12px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Action
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text
              style={{
                padding: "20px",
                fontSize: "12px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Risk
            </Text>
          </View>
          <View style={styles.armSectionHeader}>
            <Text
              style={{
                padding: "20px",
                fontSize: "12px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Mitigator
            </Text>
          </View>
        </View>
        <View style={styles.column}>
          {actions.map((arm) => {
            return (
              <View
                key={arm.arm.a.name}
                style={[styles.row, { borderTop: "4px solid black" }]}
              >
                <View style={styles.armSectionHeader}>
                  <Text style={[styles.text]}>
                    {arm.arm.a.name} {getActionUnplanned(arm.arm.a.id)}
                  </Text>
                  {arm.observationArmMediaFiles.map((i) => (
                    <ObsImage key={i.id} img={i} />
                  ))}
                </View>
                <View style={styles.column}>
                  {findRisks(arm.arm.a).filter((r) => r.arm.r).length === 0 ? (
                    <View
                      style={[
                        styles.row,
                        {
                          width: "370px",
                        },
                      ]}
                    ></View>
                  ) : (
                    findRisks(arm.arm.a)
                      .filter((r) => r.arm.r)
                      .map((rm, index) => {
                        const length =
                          findRisks(arm.arm.a).filter((r) => r.arm.r).length -
                          1;
                        if (rm.arm.r) {
                          return (
                            <View
                              key={rm.arm.r.name}
                              style={[
                                styles.row,
                                {
                                  borderBottom:
                                    index === length
                                      ? "none"
                                      : "3px solid grey",
                                },
                              ]}
                            >
                              <View style={styles.armSectionHeader}>
                                <Text style={styles.text}>
                                  {!findExpected(rm) && "(+)"} {rm.arm.r.name}
                                </Text>
                                {rm.observationArmMediaFiles.map((i) => (
                                  <ObsImage key={i.id} img={i} />
                                ))}
                              </View>
                              <View style={styles.column}>
                                {findMitigators(rm.arm).map((m, index) => {
                                  return (
                                    <View
                                      key={m.arm.m.name}
                                      style={[
                                        styles.armSectionHeader,
                                        {
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          borderBottom: getLastMit(
                                            rm.arm,
                                            index
                                          )
                                            ? "none"
                                            : "1px solid black",
                                        },
                                      ]}
                                      wrap={false}
                                    >
                                      <Text
                                        style={[
                                          styles.text,
                                          {
                                            width: "170px",
                                            color: "black",
                                          },
                                        ]}
                                      >
                                        {checkCorrection(m.arm) && (
                                          <View>
                                            <Image
                                              src={checkbox}
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                              }}
                                            />
                                          </View>
                                        )}
                                        {m.arm.m.name}{" "}
                                        {checkRootCauses(m) && (
                                          <Text
                                            style={{
                                              fontFamily: "Helvetica-Bold",
                                            }}
                                          >
                                            (Root Cause)
                                          </Text>
                                        )}
                                      </Text>
                                      {m.observationArmMediaFiles.map((i) => (
                                        <ObsImage key={i.id} img={i} />
                                      ))}
                                    </View>
                                  );
                                })}
                                {findUnselectedMitigators(rm.arm).map(
                                  (m, index) => {
                                    return (
                                      <View
                                        key={m.m.name}
                                        style={[
                                          styles.armSectionHeader,
                                          {
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            borderBottom: getLastUnselectedMit(
                                              rm.arm,
                                              index
                                            )
                                              ? "none"
                                              : "1px solid black",
                                          },
                                        ]}
                                        wrap={false}
                                      >
                                        <View
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                          wrap={false}
                                        >
                                          {checkCorrection(m) && (
                                            <View>
                                              <Image
                                                src={checkbox}
                                                style={{
                                                  width: "15px",
                                                  height: "15px",
                                                }}
                                              />
                                            </View>
                                          )}
                                          <Text
                                            style={[
                                              styles.text,
                                              {
                                                color: "red",
                                              },
                                            ]}
                                          >
                                            {m.m?.name}{" "}
                                            {checkRootCauses(m) ? (
                                              <Text
                                                style={{
                                                  fontFamily: "Helvetica-Bold",
                                                }}
                                              >
                                                (Root Cause)
                                              </Text>
                                            ) : null}
                                          </Text>
                                        </View>
                                        {getCorrection(m).map((c) => {
                                          if (
                                            c.observationArmMediaFiles.length >
                                            0
                                          ) {
                                            return c.observationArmMediaFiles.map(
                                              (i) => (
                                                <ObsImage key={i.id} img={i} />
                                              )
                                            );
                                          } else return null;
                                        })}
                                      </View>
                                    );
                                  }
                                )}
                              </View>
                            </View>
                          );
                        }
                      })
                  )}
                </View>
              </View>
            );
          })}
          {observation.observationArms.length > 0 ? (
            <View style={{ borderBottom: "4px solid black" }} />
          ) : null}
        </View>
      </View>
      <View
        style={{
          border: "3px solid black",
          marginTop: "20px",
          width: "120px",
          margin: "0 auto",
        }}
        wrap={false}
      >
        <Text
          style={[
            styles.text,
            {
              padding: "5px",
            },
          ]}
        >
          Mitigator Legend:
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            padding: "5px",
          }}
        >
          <Image
            src={checkbox}
            style={{
              width: "15px",
              height: "15px",
            }}
          />
          <Text
            style={[
              styles.text,
              {
                color: "red",
                padding: "0px",
                marginLeft: "5px",
              },
            ]}
          >
            Correction
          </Text>
        </View>
        <Text
          style={[
            styles.text,
            {
              color: "red",
              padding: "5px",
            },
          ]}
        >
          Unselected Mitigator
        </Text>
        <Text style={[styles.text, { padding: "5px" }]}>
          Selected Mitigator
        </Text>
      </View>
    </Page>
  );
}

ARMSection.propTypes = {
  observation: PropTypes.object,
  arms: PropTypes.array,
};
