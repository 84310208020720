import * as React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { onlineVar } from "../../../../graphql/localVariables/user";
import { GET_COMPANIES } from "../../../../graphql/queries";
import { GET_PERMISSION_GROUPS } from "../../../../graphql/queries/permissions";
import sortByKeys from "../../../../utils/sortByKeys";

export default function TableFilterDialog({
  open,
  setOpen,
  groupsFilter,
  onGroupChange,
  companyFilter,
  onCompanyChange,
  clearFilters,
}) {
  const online = useReactiveVar(onlineVar);
  const { data: groups } = useQuery(GET_PERMISSION_GROUPS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data } = useQuery(GET_COMPANIES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-user-company"
              options={data ? sortByKeys(data.companies, "name") : []}
              value={companyFilter}
              getOptionLabel={(option) => option.name}
              onChange={onCompanyChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Company:"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-groups"
              options={
                groups ? sortByKeys(groups.PermissionGroups, "name") : []
              }
              getOptionLabel={(option) => {
                return option.name;
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              value={groupsFilter}
              onChange={onGroupChange}
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Permission Groups:"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  groupsFilter: PropTypes.object.isRequired,
  onCompanyChange: PropTypes.func.isRequired,
  companyFilter: PropTypes.object.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
