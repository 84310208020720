import React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { Fab, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import PropTypes from "prop-types";

const styles = () => ({
  imageContainer: {
    position: "relative",
    zIndex: 10,
    textAlign: "center",
    "&:hover $image": {
      opacity: 0.3,
    },
    "&:hover $removeButton": {
      opacity: 1,
    },
    padding: "20px",
  },
  image: {
    maxWidth: "100%",
    width: "auto",
    maxHeight: "100%",
    height: "100px",
  },
  removeButton: {
    transition: ".5s ease",
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0,
    width: 40,
    height: 40,
    "&:focus": {
      opacity: 1,
    },
  },
});

function PreviewList({
  form,
  fileObjects,
  classes,
  handleFilesChange,
  updateObsKeyFunction,
}) {
  const isImage = (file) => {
    if (file.type && file.type.split("/")[0] === "image") {
      return true;
    }
  };

  const getPreviewIcon = (fileObject, classes) => {
    if (isImage(fileObject.originalFile)) {
      return (
        <img
          className={classes.image}
          src={URL.createObjectURL(fileObject.originalFile)}
          alt="Observation"
        />
      );
    }

    if (fileObject.originalFile.id) {
      return (
        <img
          className={classes.image}
          src={fileObject.originalFile.file}
          alt="Observation"
        />
      );
    }

    return <AttachFileIcon className={classes.image} />;
  };

  const handleRemove = (fileIndex) => (event) => {
    event.stopPropagation();
    const arr = [...fileObjects];
    if (arr[fileIndex].originalFile) {
      updateObsKeyFunction("removeMediaFiles", [
        ...(form.removeMediaFiles ?? []),
        arr[fileIndex].originalFile.id,
      ]);
    }
    arr.splice(fileIndex, 1);
    handleFilesChange(arr);
  };

  const getLabel = (item) => {
    if (item.m?.id) {
      return `${item.m.name} [${item.r.name}] [${item.a.name}]`;
    } else if (item.r?.id) {
      return `${item.r.name} [${item.a.name}]`;
    } else {
      return `${item.a.name}`;
    }
  };

  return (
    <Grid container sx={{ maxHeight: 350, overflowY: "auto" }}>
      {fileObjects?.map((fileObject, i) => {
        return (
          <Grid
            item
            xs={6}
            md={3}
            key={`${fileObject.file?.name ?? "file"}-${i}`}
            className={classes.imageContainer}
          >
            {getPreviewIcon(fileObject, classes)}
            <Typography style={{ fontSize: "10px" }}>
              {getLabel(fileObject.associatedItem)}
            </Typography>

            <Fab
              onClick={handleRemove(i)}
              aria-label="Delete"
              className={classes.removeButton}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        );
      })}
    </Grid>
  );
}

PreviewList.propTypes = {
  form: PropTypes.object,
  fileObjects: PropTypes.array,
  classes: PropTypes.object,
  handleFilesChange: PropTypes.func,
  updateObsKeyFunction: PropTypes.func,
};

export default withStyles(styles, { name: "MuiDropzonePreviewList" })(
  PreviewList
);
