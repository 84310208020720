import React from "react";

import { Text, View } from "@react-pdf/renderer";

import sortByKeys from "../../../../utils/sortByKeys";
import { styles } from "./styles";

export default function MitigatorsSection({ mitigators, riskIndex, risks }) {
  return (
    <View>
      {mitigators.length === 0 ? (
        <View style={[styles.armSection]}>
          <Text style={[styles.text, { color: "white" }]}>
            {risks[riskIndex].arm.r.name}
          </Text>
          {riskIndex === risks.length - 1 && <Text style={styles.text}> </Text>}
        </View>
      ) : (
        sortByKeys(mitigators, "arm.m.name").map((mit) => {
          return (
            <View
              key={mit.arm.m.name}
              style={[
                styles.armSection,
                {
                  minHeight: "50px",
                },
              ]}
            >
              <Text style={styles.text}>{mit.arm.m.name}</Text>
              {mitigators.length === 1 && riskIndex === risks.length - 1 && (
                <Text style={styles.text}> </Text>
              )}
            </View>
          );
        })
      )}
    </View>
  );
}
