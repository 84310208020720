import React from "react";
import { useParams } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { MUTATE_CATEGORY_ACTION } from "../../../../graphql/mutations/admin/arm";
import {
  GET_CATEGORIES,
  GET_CATEGORY_ACTIONS,
  GET_COMPANY_ARMS_ADMIN,
} from "../../../../graphql/queries/admin/arm";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import sortByKeys from "../../../../utils/sortByKeys";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import EditCategory from "./EditCategory";

function AssociatedAction({ action, remove, disabled }) {
  return (
    <Grid
      key={action.id}
      container
      style={{
        padding: "10px",
        height: "fit-content",
      }}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Grid item xs={2}>
        <IconButton onClick={() => remove(action)} disabled={disabled}>
          <CheckIcon />
        </IconButton>
      </Grid>
      <Grid item xs={10}>
        <Typography style={{ color: "black" }}>
          {action.a ? action.a.name : action.name}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function CategoryPage({ id, handleClose: handleParentClose }) {
  const permissions = useReactiveVar(permissionsVar);
  const { companyId } = useParams();

  const { data: user } = useQuery(GET_CURRENT_USER);

  const { data: categories } = useQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
  });
  const { data: categoryActions, loading } = useQuery(GET_CATEGORY_ACTIONS, {
    variables: { categoryId: Number(id) },
    fetchPolicy: "network-only",
  });

  const [getCompanyActions, { data: actions, loading: loadingAction }] =
    useLazyQuery(GET_COMPANY_ARMS_ADMIN, {
      fetchPolicy: "network-only",
    });

  const [mutateCategoryBatchActions] = useMutation(MUTATE_CATEGORY_ACTION, {
    onCompleted() {
      onCompletedFunc("Category has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
  });

  // CATEGORY STATE
  const [category, setCategory] = React.useState({ name: "" });

  const [associatedActions, setAssociatedActions] = React.useState([]);
  const [addedActions, setAddedActions] = React.useState([]);
  const [updateAssociatedAction, setUpdateAssociatedAction] = React.useState(
    []
  );
  const [actionSearch, setActionSearch] = React.useState("");

  React.useEffect(() => {
    if (user) {
      const idToUse = companyId
        ? Number(companyId)
        : Number(user.currentUser.company?.id);

      getCompanyActions({
        variables: {
          companyId: Number(idToUse),
          rNull: true,
          mNull: true,
        },
      });
    }
  }, [user, companyId]);

  React.useEffect(() => {
    if (categories && id) {
      setCategory(categories.categories.find((c) => c.id === id));
    }
  }, [categories, id]);

  React.useEffect(() => {
    if (categoryActions) {
      setAssociatedActions(categoryActions.categoryActions);
    }
  }, [categoryActions]);

  React.useEffect(() => {
    setUpdateAssociatedAction([...associatedActions, ...addedActions]);
  }, [associatedActions, addedActions]);

  const handleSearchChange = (event) => {
    setActionSearch(event.target.value);
  };

  const add = (a) => {
    setAddedActions((prev) => [...prev, a]);
  };

  const remove = (a) => {
    setAssociatedActions((prev) => prev.filter((p) => p.action.id !== a.id));
    setAddedActions((prev) => prev.filter((p) => p.id !== a.id));
  };

  const onSave = () => {
    const assoArmInfo = associatedActions.map((cat) => Number(cat.action.id));
    const addedArmInfo = addedActions.map((a) => Number(a.a.id));
    const unassociated = actions?.companyArms
      .filter(
        (carm) =>
          associatedActions.findIndex(
            (cat) => cat.action.id === carm.arm.a.id
          ) < 0 &&
          addedActions.findIndex((action) => action.id === carm.arm.a.id) < 0
      )
      .map((carm) => Number(carm.arm.a.id));

    mutateCategoryBatchActions({
      variables: {
        category: Number(category.id),
        associatedActions: [...assoArmInfo, ...addedArmInfo],
        unassociatedActions: unassociated,
      },
    });
    handleParentClose();
  };

  const onCancel = () => {
    if (categoryActions) {
      setAssociatedActions(categoryActions.categoryActions);
    }
    setAddedActions([]);
    handleParentClose();
  };

  const canEdit =
    permissions.includes("EDIT_CATEGORY") || permissions.includes("CATEGORY");

  return (
    <>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{
                ...pageTitleStyles,
              }}
            >
              CATEGORY:{" "}
              <span style={{ color: "#FFB700" }}>{category.name}</span>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <Grid item>
              {category.isActive ? (
                <Chip label="ACTIVE" color="yellow0" />
              ) : (
                <Chip label="INACTIVE" color="secondary" />
              )}
            </Grid>
            {canEdit && (
              <Grid item>
                <EditCategory category={category} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={7}>
            <Typography style={{ color: "white" }}>
              ASSOCIATED ACTIONS:
            </Typography>
            {loading ? (
              <Grid container style={{ width: "100%" }} justifyContent="center">
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  width: "95%",
                  marginTop: "10px",
                  borderRadius: 5,
                }}
              >
                {updateAssociatedAction.length === 0 ? (
                  <Typography style={{ padding: "20px", color: "black" }}>
                    No associated actions.
                  </Typography>
                ) : (
                  updateAssociatedAction
                    .sort((a, b) => (a.action?.name > b.action?.name ? 1 : -1))
                    .map((action) => (
                      <AssociatedAction
                        key={action.id}
                        action={action.action ? action.action : action}
                        remove={remove}
                        disabled={!canEdit}
                      />
                    ))
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={5}>
            <Typography style={{ color: "white" }}>
              UNASSOCIATED ACTIONS:
            </Typography>
            <CustomTextField
              label="SEARCH:"
              variant="standard"
              value={actionSearch}
              onChange={handleSearchChange}
              fullWidth
              sx={{ my: 1 }}
            />
            {loadingAction ? (
              <Grid container style={{ width: "100%" }} justifyContent="center">
                <CircularProgress color="secondary" />
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                {sortByKeys(
                  actions?.companyArms
                    ? actions.companyArms
                        .filter(
                          (carm) =>
                            associatedActions.findIndex(
                              (cat) => cat.action.id === carm.arm.a.id
                            ) < 0 &&
                            addedActions.findIndex(
                              (action) => action.a.id === carm.arm.a.id
                            ) < 0 &&
                            carm.arm.a.name
                              .toLowerCase()
                              .includes(actionSearch.toLowerCase())
                        )
                        .map((carm) => carm.arm)
                    : [],
                  "a.name"
                ).map((a) => (
                  <Grid
                    item
                    xs={12}
                    key={a.id}
                    style={{
                      padding: "10px",
                      height: "fit-content",
                      color: "black",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <IconButton
                        style={{ marginRight: "10px" }}
                        onClick={() => add(a)}
                        disabled={!canEdit}
                      >
                        <CheckBoxOutlineBlankIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography>{a.a.name}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
          disabled={!canEdit}
        >
          SAVE
        </Button>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </>
  );
}
