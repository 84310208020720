import React from "react";

import { Text, View } from "@react-pdf/renderer";

import MitigatorsSection from "./MitigatorsSection";
import { styles } from "./styles";

export default function RiskSection({ risks, ha }) {
  const findMitigators = (ar) => {
    const allMitigators = ha.haArms.filter(
      (oa) =>
        oa.arm.r?.name === ar.r?.name &&
        oa.arm.r?.id === ar.r?.id &&
        oa.arm.a.name === ar.a.name &&
        oa.arm.a.id === ar.a.id
    );
    const unique = (arr) =>
      arr.reduce((results, item) => {
        if (item.arm.m !== null) {
          results.push(item);
        }
        return results;
      }, []);
    return unique(allMitigators);
  };

  return (
    <View>
      {risks.length === 0 ? (
        <View style={[styles.armSection]}>
          <Text style={styles.text}> </Text>
          <Text style={styles.text}> </Text>
        </View>
      ) : (
        risks
          .sort((a, b) => {
            const aName = a.arm.r.name;
            const bName = b.arm.r.name;

            const aExpected = a.arm.isExpected;
            const bExpected = b.arm.isExpected;

            if ((aExpected && bExpected) || (!aExpected && !bExpected)) {
              return aName > bName ? 1 : -1;
            }
            if (aExpected && !bExpected) return -1;
            if (!aExpected && bExpected) return 1;
          })
          .map((risk, index) => {
            return (
              <View key={risk.arm.r?.name} style={[styles.row]}>
                <View
                  style={[
                    styles.armSection,
                    {
                      flex: 1,
                      borderBottom:
                        index === risks.length - 1 ? "none" : "1px solid black",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.text,
                      {
                        textAlign: "center",
                      },
                    ]}
                  >
                    {risk.arm.r?.name}
                  </Text>
                  {index === risks.length - 1 && (
                    <Text style={styles.text}> </Text>
                  )}
                </View>
                <View style={[styles.column, { flex: 1 }]}>
                  <MitigatorsSection
                    mitigators={findMitigators(risk.arm)}
                    riskIndex={index}
                    risks={risks}
                  />
                </View>
              </View>
            );
          })
      )}
    </View>
  );
}
