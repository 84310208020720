import React from "react";

import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import Question from "./Question";
import Responses from "./Responses";
import styles from "./styles";

export default function Topic({ topic, i }) {
  if (
    topic.name === "Incident Classification - US (OSHA)" &&
    i.type !== "Injury"
  )
    return (
      <View>
        <Text> </Text>
      </View>
    );

  const fullWidthQuestions = [
    "What Happened?",
    "Where the event occurred (e.g. Loading dock north end)",
    "Describe the event",
  ];

  return (
    <View style={{ width: "100%" }}>
      {topic.name !== "Incident Details - US (OSHA)" &&
        topic.name !== "General" && (
          <Text
            style={[
              styles.header,
              {
                borderTop: "3px solid grey",
                paddingTop: "10px",
                fontSize: "16px",
              },
            ]}
          >
            {topic.name.toUpperCase()}
          </Text>
        )}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {sortByKeys(topic.incidentQuestions, "ordering").map((q) => (
          <View
            key={q.id}
            style={[
              {
                width: fullWidthQuestions.includes(q.name) ? "100%" : "48%",
                marginVertical: "10px",
                marginRight: "5px",
              },
            ]}
          >
            <Text style={[styles.header]}>{q.name}</Text>

            {i.incidentQuestionResponses.find(
              (resp) => resp.incidentQuestion.id === q.id
            ) ? (
              <Question
                question={i.incidentQuestionResponses.find(
                  (resp) => resp.incidentQuestion.id === q.id
                )}
              />
            ) : (
              <View>
                <Text> </Text>
              </View>
            )}
          </View>
        ))}
      </View>

      <Responses id={topic.id} i={i} />
    </View>
  );
}

Topic.propTypes = {
  topic: PropTypes.object,
  i: PropTypes.object,
};
