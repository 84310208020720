import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import PropTypes from "prop-types";

import logo from "../../../../images/nixn-logo-white.png";
import sortByKeys from "../../../../utils/sortByKeys";
import Topic from "./Topic";
import styles from "./styles";

const ReportHeader = ({ i }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90px",
        }}
      >
        <Text style={{ fontFamily: "Helvetica-Bold" }}>INCIDENT</Text>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>REPORT</Text>
      </View>
      {i.type.toUpperCase() === "PROPERTY DAMAGE" ? (
        <Text
          style={{
            width: "85px",
            fontSize: 14,
          }}
        >
          {i.type.toUpperCase()}
        </Text>
      ) : (
        <Text
          style={{
            width: "75px",
          }}
        >
          {i.type.toUpperCase()}
        </Text>
      )}
      <View style={{ width: "40%", borderBottom: "3px solid #FCA800" }}></View>
      {i.submitter?.company?.logoBase64 !== "" ? (
        <Image
          src={`data:image;base64,${i.submitter.company.logoBase64}`}
          style={{ maxWidth: "100px" }}
        />
      ) : (
        <Image src={logo} style={{ maxWidth: "100px" }} />
      )}
    </View>
  );
};

const convertDate = (dateString) => {
  const date = new Date(dateString);
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  return convertedDate;
};

export default function GeneralInformation({ i }) {
  const convertedDate = convertDate(i.date);

  const startTime = moment();
  const timeArr = i.time.split(":");
  startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));

  const injuryTime = moment();
  const injuryTimeArr = i.injuryTime.split(":");
  injuryTime.hours(Number(injuryTimeArr[0])).minutes(Number(injuryTimeArr[1]));

  const convertedDob = convertDate(i.employeeDob);

  const convertedIncidentDate = convertDate(i.injuryDate);

  const sortedTopics = sortByKeys(i.incident.incidentTopics, "ordering");

  return (
    <Page size="A4" style={{ padding: 20 }}>
      <View>
        <ReportHeader i={i} />

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <View style={{ width: "100%" }}>
            <Text
              style={[
                styles.header,
                {
                  fontSize: "16px",
                  paddingBottom: "20px",
                  borderTop: "3px solid grey",
                  paddingTop: "10px",
                },
              ]}
            >
              GENERAL INFORMATION
            </Text>
          </View>

          <View style={styles.question}>
            <Text style={styles.header}>Type</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.type}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Date</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {moment(new Date(convertedDate)).format("MM/DD/YYYY")}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Time</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {startTime.format("hh:mm A")}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Owner</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.ownerSiteProject.owner?.name}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Site</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.ownerSiteProject.site?.name}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Project</Text>
            {i.ownerSiteProject.project && (
              <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
                {i.ownerSiteProject.project?.name}
              </Text>
            )}
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>HA</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.ha?.name ?? "--"}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Weather</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.weather}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Submitted By</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.submitter.username}
            </Text>
          </View>

          {/* GENERAL */}
          <Topic topic={sortedTopics[0]} i={i} />

          <View style={{ width: "100%" }}>
            <Text
              style={[
                styles.header,
                {
                  fontSize: "16px",
                  paddingBottom: "20px",
                  borderTop: "3px solid grey",
                  paddingTop: "10px",
                },
              ]}
            >
              INCIDENT DETAILS - US (OSHA)
            </Text>
          </View>

          <View style={styles.question}>
            <Text style={styles.header}>Employee Name</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.employeeName}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Employee Job Title</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.employeeJobTitle}
            </Text>
          </View>
          <View style={styles.question}>
            <Text style={styles.header}>Employee Phone Number</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {i.employeePhoneNumber}
            </Text>
          </View>
          <View style={styles.question} wrap={false}>
            <Text style={styles.header}>Employee Date of Birth</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {moment(convertedDob).format("MM/DD/YYYY")}
            </Text>
          </View>
          <View style={styles.question} wrap={false}>
            <Text style={styles.header}>Incident Date/Time</Text>
            <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
              {moment(convertedIncidentDate).format("MM/DD/YYYY")}{" "}
              {injuryTime.format("hh:mm A")}
            </Text>
          </View>

          {/* INCIDENT DETAILS */}
          <Topic topic={sortedTopics[1]} i={i} />
        </View>
      </View>
    </Page>
  );
}

GeneralInformation.propTypes = {
  i: PropTypes.object,
};
