import React from "react";

import { Typography } from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_INCIDENT_REPORTS } from "../../../graphql/queries/incidentInvestigations";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import Table from "./Table";

export default function ViewInvestigation() {
  const { data, loading } = useQuery(GET_INCIDENT_REPORTS, {
    fetchPolicy: "no-cache",
  });

  return (
    <Layout>
      <Typography
        sx={{ ...pageTitleStyles, fontSize: { xs: "2rem", md: "3rem" } }}
      >
        VIEW INCIDENT INVESTIGATIONS.
      </Typography>
      <Table data={data ? data.incidentReports : []} loading={loading} />
    </Layout>
  );
}
