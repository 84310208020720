import React from "react";

import removeDuplicates from "../../../../../../../utils/removeDuplicates";
import TableTemplate from "../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const initialState = {
    searchterm: "",
    site: { owner: { name: "" } },
    afterDate: null,
    beforeDate: null,
  };
  const [filters, setFilters] = React.useState(initialState);
  const [filteredData, setFilteredData] = React.useState(data);

  const clearFilters = () => {
    setFilters(initialState);
  };

  React.useEffect(() => {
    handleFilter();
  }, [filters.searchterm]);

  const handleFilter = () => {
    setFilteredData(
      filterData(
        data,
        filters.searchterm,
        filters.site,
        filters.afterDate,
        filters.beforeDate
      )
    );
  };

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData ?? []}
      handleFetchMore={() => {}}
      paginationCount={filteredData.length}
      title="Audits"
      headCells={[
        {
          uid: "id",
          numeric: false,
          disablePadding: false,
          label: "Audit ID",
          sort: true,
        },
        {
          uid: "date",
          numeric: true,
          disablePadding: false,
          label: "Date",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: true,
          disablePadding: false,
          label: "Time Created",
          sort: true,
        },
        {
          uid: "submitter.username",
          numeric: true,
          disablePadding: false,
          label: "Submitter Username",
          sort: true,
        },
        {
          uid: "Location",
          numeric: true,
          disablePadding: false,
          label: "Location",
        },
        {
          uid: "type",
          numeric: true,
          disablePadding: false,
          label: "Type",
          sort: true,
        },
      ]}
      initialOrderBy="id"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "submitter.username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "date":
            aItem = new Date(`${a.date} ${a.time}`);
            bItem = new Date(`${b.date} ${b.time}`);
            break;
          case "type":
            aItem = a.audit.name;
            bItem = b.audit.name;
            break;
          case "id":
            aItem = Number(a.id);
            bItem = Number(b.id);
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }
        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen, setPage) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
          clearFilters={clearFilters}
          onFilter={() => {
            handleFilter();
            setPage(0);
          }}
          ownerSiteProjects={removeDuplicates(
            data
              .filter((d) => d.ownerSiteProject)
              .map((d) => d.ownerSiteProject),
            "id"
          )}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchTerm) =>
        setFilters((prev) => ({ ...prev, searchterm: searchTerm }))
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={false}
    />
  );
}
