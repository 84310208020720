import React from "react";

import {
  Grid,
  Typography,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_SIMPLE_COMPANIES } from "../../../graphql/queries/admin/company";
import sortByKeys from "../../../utils/sortByKeys";
import { CustomSwitch } from "../../CustomComponents/Switch";

// Type - 'action', 'risk', or 'mitigator'
export default function CompanyAssign({
  type,
  assignedCompany,
  setAssignedCompany,
  currentUser: userData,
}) {
  const { data: companies } = useQuery(GET_SIMPLE_COMPANIES, {
    fetchPolicy: "network-only",
  });

  const [assignedToYourCompany, setAssigned] = React.useState(true);

  React.useEffect(() => {
    if (userData?.currentUser) {
      setAssignedCompany(userData.currentUser.company);
    }
  }, [userData]);

  return (
    <Grid
      container
      textAlign={"center"}
      sx={{ marginBottom: { xs: "25px", md: "0px" } }}
    >
      <Grid item xs={12}>
        <Typography sx={{ display: "inline-flex" }}>
          Would you like the owner of this {type} to be assigned to{" "}
          {userData?.currentUser
            ? userData.currentUser.company.name
            : "your company"}
          ?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          style={{ color: "black" }}
          control={
            <CustomSwitch
              checked={assignedToYourCompany}
              onChange={() => setAssigned((prev) => !prev)}
              value={true}
            />
          }
          label={assignedToYourCompany ? "YES" : "NO"}
        />
      </Grid>

      {!assignedToYourCompany ? (
        <Grid
          container
          item
          xs={12}
          alignContent={"center"}
          justifyContent="center"
        >
          <Autocomplete
            id="select-company"
            options={
              companies?.companies
                ? sortByKeys(companies.companies, "name")
                : []
            }
            value={assignedCompany}
            onChange={(event, value) => setAssignedCompany(value)}
            getOptionLabel={(option) => option.name}
            disableClearable
            style={{ width: "75%", marginBottom: "20px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={"Company to assign to:"}
              />
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
