import React from "react";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { MUTATE_PERMISSION } from "../../../graphql/mutations/admin/permissions";
import {
  GET_ACTIVE_PERMISSIONS,
  GET_PERMISSION_GROUPS,
} from "../../../graphql/queries/permissions";
import sortByKeys from "../../../utils/sortByKeys";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import ShowInactiveFilter from "../../CustomComponents/ShowInactiveFilter";
import { CustomSwitch } from "../../CustomComponents/Switch";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import CreateGroup from "./CreateGroup";
import PermissionGroup from "./PermissionGroup";

function Permission({ permission }) {
  const [updatePermission] = useMutation(MUTATE_PERMISSION, {
    onCompleted() {
      onCompletedFunc("Permission has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_ACTIVE_PERMISSIONS, fetchPolicy: "no-cache" },
    ],
  });
  const [name, setName] = React.useState(permission.name);
  const [description, setDescription] = React.useState(permission.description);
  const [isActive, setIsActive] = React.useState(permission.isActive);

  const onSave = () => {
    updatePermission({
      variables: {
        input: {
          id: Number(permission.id),
          name,
          description,
          isActive,
        },
      },
    });
  };

  const onCancel = () => {
    setName(permission.name);
    setDescription(permission.description);
    setIsActive(permission.isActive);
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls="permissions-content"
        id="permissions-header"
        sx={{
          backgroundColor: isActive ? "grey1.main" : "grey0.main",
          color: "white !important",
        }}
      >
        {permission.name}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: isActive ? "grey1.main" : "grey0.main",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ color: "white" }}
              control={
                <CustomSwitch
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)}
                />
              }
              label={isActive ? "ACTIVE" : "INACTIVE"}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              variant="standard"
              label="Name:"
              value={name}
              onChange={(event) => setName(event.target.value)}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              variant="standard"
              label="Description:"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              inputProps={{ maxLength: 100 }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }}>
          <Button
            onClick={onSave}
            color="yellow0"
            variant="contained"
            sx={{ mr: 1 }}
          >
            SAVE
          </Button>
          <Button variant="contained" onClick={onCancel}>
            CANCEL
          </Button>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default function PermissionsAdmin() {
  const navigate = useNavigate();
  const permissionsState = useReactiveVar(permissionsVar);

  const { data: groups, loading: loadingGroups } = useQuery(
    GET_PERMISSION_GROUPS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: permissions, loading: loadingPermissions } = useQuery(
    GET_ACTIVE_PERMISSIONS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [search, setSearch] = React.useState("");
  const [showInActive, setShowInActive] = React.useState(true);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (!permissionsState.includes("VIEW_PERMISSION_ADMINISTRATION")) {
      navigate("/");
    }
  }, []);

  const shownGroups = groups?.PermissionGroups
    ? sortByKeys(
        groups.PermissionGroups.filter((pg) =>
          pg.name.toLowerCase().includes(search.toLowerCase())
        ),
        "name"
      )
    : [];

  const shownPermissions = permissions?.permissions
    ? sortByKeys(
        permissions.permissions.filter((p) =>
          p.name.toLowerCase().includes(search.toLowerCase())
        ),
        "name"
      )
    : [];

  const tabHeader = (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 2 }}
    >
      <TextField
        label="SEARCH:"
        variant="standard"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <ShowInactiveFilter
        showInActive={showInActive}
        setShowInActive={setShowInActive}
      />
    </Grid>
  );

  return (
    <Layout>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography sx={pageTitleStyles}>PERMISSION ADMINISTRATION.</Typography>

        <Grid item>
          <CreateGroup permissions={permissions} />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/admin/user")}
          >
            VIEW USER PERMISSIONS
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ p: 1 }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          style={{ color: "white" }}
        >
          <StyledTab label="GROUPS" />
          <StyledTab label="PERMISSIONS" />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          {tabHeader}
          {loadingGroups && (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
          {!loadingGroups && permissions && groups && (
            <>
              {shownGroups.length === 0 ? (
                <Typography>No results.</Typography>
              ) : (
                <>
                  {shownGroups
                    .filter((pg) => pg.isActive)
                    .map((pg) => (
                      <PermissionGroup
                        key={pg.id}
                        permissionGroup={pg}
                        allPermissions={permissions}
                      />
                    ))}

                  {showInActive &&
                    shownGroups
                      .filter((pg) => !pg.isActive)
                      .map((pg) => (
                        <PermissionGroup
                          key={pg.id}
                          permissionGroup={pg}
                          allPermissions={permissions}
                        />
                      ))}
                </>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {tabHeader}
          {loadingPermissions && (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
          {!loadingPermissions && permissions && (
            <>
              {shownPermissions.length === 0 ? (
                <Typography>No results.</Typography>
              ) : (
                <>
                  {shownPermissions
                    .filter((p) => p.isActive)
                    .map((p) => (
                      <Permission key={p.id} permission={p} />
                    ))}

                  {showInActive &&
                    shownPermissions
                      .filter((p) => !p.isActive)
                      .map((p) => <Permission key={p.id} permission={p} />)}
                </>
              )}
            </>
          )}
        </TabPanel>
      </Paper>
    </Layout>
  );
}
