import React from "react";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { MUTATE_RISK } from "../../../../graphql/mutations/admin/arm";
import { GET_GLOBAL_RISKS } from "../../../../graphql/queries/admin/arm";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";

export default function Risk({ risk: originalRisk, handleClose }) {
  const permissions = useReactiveVar(permissionsVar);

  const { data: user } = useQuery(GET_CURRENT_USER);

  const [mutateRisk] = useMutation(MUTATE_RISK, {
    onCompleted() {
      onCompletedFunc("Risk has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_GLOBAL_RISKS }],
  });

  const [canEdit, setCanEdit] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      setCanEdit(user.currentUser.isStaff);
    }
  }, [user]);

  // RISK STATE
  const [risk, setRisk] = React.useState({ id: "", name: "" });
  const [isActive, setIsActive] = React.useState(false);
  const [riskName, setRiskName] = React.useState("");

  React.useEffect(() => {
    if (originalRisk) {
      setRisk(originalRisk);
    }
  }, [originalRisk]);

  React.useEffect(() => {
    if (risk.name !== "") {
      setIsActive(risk.isActive);
      setRiskName(risk.name);
    }
  }, [risk]);

  const saveEdit = () => {
    mutateRisk({
      variables: {
        id: Number(risk.id),
        name: riskName,
        isActive,
      },
    });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  return (
    <>
      <DialogContent>
        <Grid
          sx={{
            backgroundColor: "#8297A0",
            textAlign: "center",
            borderRadius: "6px",
            padding: "10px",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>
            Currently editing global risk. Only allowed by MAC Safety users.
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography
              style={{
                ...pageTitleStyles,
              }}
            >
              RISK: <span style={{ color: "#FFB700" }}>{risk.name}</span>
            </Typography>
          </Grid>
        </Grid>
        {risk.id !== "" ? (
          <Grid container alignItems="center">
            {permissions.includes("ARM") && (
              <Grid item container alignItems="center">
                <FormControlLabel
                  style={{ color: "white" }}
                  control={
                    <CustomSwitch
                      checked={isActive}
                      onChange={(event) => setIsActive(event.target.checked)}
                      disabled={!canEdit}
                    />
                  }
                  label={isActive ? "ACTIVE" : "INACTIVE"}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <CustomTextField
                variant="standard"
                value={riskName}
                label="Risk Name:"
                onChange={(event) => setRiskName(event.target.value)}
                disabled={!canEdit}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ width: "100%" }} justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={saveEdit}
        >
          SAVE
        </Button>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </>
  );
}
