import React from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../graphql/localVariables/user";
import { MUTATE_CLASSIFICATION } from "../../../graphql/mutations/admin/industry";
import { GET_CLASSIFICATIONS } from "../../../graphql/queries/admin/industry";
import sortByKeys from "../../../utils/sortByKeys";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import ShowInactiveFilter from "../../CustomComponents/ShowInactiveFilter";
import { CustomSwitch } from "../../CustomComponents/Switch";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import Layout from "../../Layout";
import CreateClassification from "./CreateClassification";
import CreateIndustry from "./CreateIndustry";
import Industries from "./Industry";

function OneClassification({ classification, canEdit }) {
  const [editClassification] = useMutation(MUTATE_CLASSIFICATION, {
    onCompleted() {
      onCompletedFunc("Classification has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_CLASSIFICATIONS }],
  });
  const [name, setName] = React.useState(classification?.name);
  const [code, setCode] = React.useState(classification?.code);
  const [isActive, setIsActive] = React.useState(classification?.isActive);

  const onSave = () => {
    editClassification({
      variables: { id: Number(classification.id), name, code, isActive },
    });
  };

  const onCancel = () => {
    setName(classification.name);
  };

  return (
    <Accordion
      style={{ width: "100%" }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore style={{ color: "white" }} />}
        id={classification?.name}
        sx={{
          backgroundColor: isActive ? "grey1.main" : "grey0.main",
          color: "white.main",
        }}
      >
        {classification?.name}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: isActive ? "grey1.main" : "grey0.main",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ color: "white" }}
              control={
                <CustomSwitch
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)}
                  disabled={!canEdit}
                />
              }
              label={isActive ? "ACTIVE" : "INACTIVE"}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              variant="standard"
              label="Classification Name:"
              value={name}
              onChange={(event) => setName(event.target.value)}
              disabled={!canEdit}
              multiline
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              variant="standard"
              label="Classification Code:"
              value={code}
              onChange={(event) => setCode(event.target.value)}
              inputProps={{ maxLength: 4 }}
              fullWidth
              disabled={!canEdit}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ margin: "20px 0px" }}>
          {canEdit && (
            <>
              <Button
                variant="contained"
                color="yellow0"
                sx={{ mr: 1 }}
                onClick={onSave}
              >
                SAVE
              </Button>
              <Button variant="contained" onClick={onCancel}>
                CANCEL
              </Button>
            </>
          )}
          <Industries
            industries={
              classification ? classification.industrialClassifications : []
            }
            canEdit={canEdit}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default function Classification() {
  const { data } = useQuery(GET_CLASSIFICATIONS);

  const permissions = useReactiveVar(permissionsVar);

  const [search, setSearch] = React.useState("");
  const [showInActive, setShowInActive] = React.useState(true);

  const [canEdit, setCanEdit] = React.useState(false);

  React.useEffect(() => {
    setCanEdit(permissions.includes("EDIT_INDUSTRY"));
  }, []);

  const shownClassifications = data?.classifications
    ? sortByKeys(
        data.classifications.filter(
          (i) =>
            i.name.toLowerCase().includes(search.toLowerCase()) &&
            (showInActive || i.isActive)
        ),
        "name"
      )
    : [];

  return (
    <Layout>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={pageTitleStyles}>INDUSTRY ADMINISTRATION.</Typography>
          {permissions.includes("EDIT_INDUSTRY") && (
            <Grid item>
              <CreateClassification />
              <CreateIndustry data={data} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <TextField
                label="SEARCH:"
                variant="standard"
                style={{ margin: "10px 0px" }}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <ShowInactiveFilter
                showInActive={showInActive}
                setShowInActive={setShowInActive}
              />
            </Grid>
            {shownClassifications.length === 0 ? (
              <Typography sx={{ my: 2, fontSize: 18 }}>No results.</Typography>
            ) : (
              shownClassifications.map((i) => (
                <OneClassification
                  key={i.id}
                  classification={i}
                  canEdit={canEdit}
                />
              ))
            )}
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}
