import React from "react";

import { useQuery } from "@apollo/client";

import { GET_TEMPLATES } from "../../../../../graphql/queries/admin/planning-template";
import TableTemplate from "../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table() {
  const { data, loading } = useQuery(GET_TEMPLATES, {
    fetchPolicy: "no-cache",
  });

  const initialState = {
    searchterm: "",
    showInActive: true,
  };
  const [filters, setFilters] = React.useState(initialState);

  const clearFilters = () => {
    setFilters(initialState);
  };

  const [filteredData, setFilteredData] = React.useState([]);

  const handleFilter = () => {
    setFilteredData(
      filterData(
        data?.templates ?? [],
        filters.searchterm,
        filters.showInActive
      )
    );
  };
  React.useEffect(() => {
    handleFilter();
  }, [filters.searchterm, data]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Templates"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Name",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: false,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "lastUpdated",
          numeric: false,
          disablePadding: false,
          label: "Last Updated",
        },
        {
          uid: "editor",
          numeric: false,
          disablePadding: false,
          label: "Last Editor",
        },
        {
          uid: "revision",
          numeric: false,
          disablePadding: false,
          label: "Revision #",
        },
        {
          uid: "associatedTo",
          numeric: false,
          disablePadding: false,
          label: "Associated To",
        },
        {
          uid: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
        },
        {
          uid: "",
          numeric: false,
          disablePadding: false,
          label: "",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a[orderBy].toLowerCase();
          bItem = b[orderBy].toLowerCase();
        } else {
          aItem = a[orderBy];
          bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
          handleFilter={handleFilter}
          clearFilters={clearFilters}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchterm) =>
        setFilters((prev) => ({ ...prev, searchterm }))
      }
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
      loading={loading}
    />
  );
}
