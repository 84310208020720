import * as React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Typography,
  FormControlLabel,
} from "@mui/material";

import PropTypes from "prop-types";

import { CustomSwitch } from "../../../../CustomComponents/Switch";

export default function TableFilterDialog({
  open,
  setOpen,
  filters,
  setFilters,
  handleFilter,
  clearFilters,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography>Show Inactive?</Typography>
            <FormControlLabel
              style={{ color: "black" }}
              control={
                <CustomSwitch
                  checked={filters.showInActive}
                  onChange={(event) =>
                    setFilters((prev) => ({
                      ...prev,
                      showInActive: event.target.checked,
                    }))
                  }
                />
              }
              label={filters.showInActive ? "YES" : "NO"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleFilter();
            setOpen(false);
          }}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onActiveChange: PropTypes.func.isRequired,
  active: PropTypes.object.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
