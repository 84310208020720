import React from "react";

import { Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import PropTypes from "prop-types";

import logo from "../../../../images/nixn-logo-white.png";
import { getGradeLetter } from "../../../../utils/raScore";

export default function SummaryPage({ ha, showScores }) {
  const d = new Date(ha.date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  let startTime = moment();
  if (ha.time) {
    const timeArr = ha.time.split(":");
    startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));
  } else {
    startTime = moment(new Date(ha.timeCompleted));
  }

  const { grade: gradeLetter, mitigatedRisk: riskMitigated } = getGradeLetter(
    ha.raScore,
    ha.raScoreMax,
    ha.raScoreMin
  );

  function toDecimalPlace(num, fixed) {
    let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  }

  return (
    <Page size="A4" orientation="landscape">
      <View
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ fontFamily: "Helvetica-Bold" }}>HAZARD</Text>
          <Text style={{ fontFamily: "Helvetica-Bold" }}>ANALYSIS</Text>
          <Text style={{ fontFamily: "Helvetica-Bold" }}>REPORT</Text>
        </View>
        <View
          style={{ width: "73%", borderBottom: "3px solid #FCA800" }}
        ></View>
        {ha.submitter.company.logoBase64 !== "" ? (
          <Image
            src={`data:image;base64,${ha.submitter.company.logoBase64}`}
            style={{ width: "100px" }}
          />
        ) : (
          <Image src={logo} style={{ width: "100px" }} />
        )}
      </View>
      <View
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {showScores && (
          <View style={{ width: "48%" }}>
            <View
              style={{
                border: "3px solid #8297A0",
                borderRadius: 10,
                padding: "10px",
              }}
            >
              <Text>
                <Text style={{ color: "#8297A0" }}>OVERALL</Text> SCORE:{" "}
                <Text style={{ color: "grey" }}>{gradeLetter}</Text>
              </Text>
              <View
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text style={{ fontSize: "12px", color: "#8297A0" }}>
                    TOTAL RISK SCORE:
                  </Text>
                  <Text style={{ fontSize: "12px", padding: "25px 0px" }}>
                    {ha.raScore.toFixed(2)}
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text
                    style={{
                      fontSize: "12px",
                      color: "#8297A0",
                      width: "75%",
                    }}
                  >
                    PERCENT OF RISK MITIGATED:
                  </Text>
                  <Text style={{ fontSize: "12px", padding: "20px 0px" }}>
                    {toDecimalPlace(riskMitigated, 2)}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        <View
          style={{
            width: "48%",
            padding: "10px",
            border: "3px solid #8297A0",
            borderRadius: 10,
          }}
        >
          <Text>SITE SPECIFIC</Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              Project Location:
            </Text>{" "}
            {ha.haOwnerSiteProjects.length > 0
              ? ha.haOwnerSiteProjects[0].ownerSiteProject.site.name
              : ""}{" "}
            [
            {ha.haOwnerSiteProjects.length > 0
              ? ha.haOwnerSiteProjects[0].ownerSiteProject.owner.name
              : ""}
            ]
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Project Name:</Text>{" "}
            {ha.haOwnerSiteProjects.length > 0
              ? ha.haOwnerSiteProjects
                  .map((haOsp) => haOsp.ownerSiteProject.project?.name ?? "")
                  .join(", ")
              : ""}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Date:</Text>{" "}
            {moment(convertedDate).format("MM/DD/YYYY")}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Time:</Text>{" "}
            {moment(startTime).format("hh:mm A")}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              Weather Conditions:
            </Text>{" "}
            {ha.weather}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>HA ID:</Text>{" "}
            {ha.haId}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              Revision Number:
            </Text>{" "}
            {ha.revisionNumber}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>Date Created:</Text>{" "}
            {moment(new Date(ha.timeCreated)).format("MM/DD/YYYY hh:mm A")}
          </Text>
          <Text style={{ padding: "10px 0px", fontSize: "14px" }}>
            <Text style={{ fontFamily: "Helvetica-Bold" }}>
              Date Last Updated:
            </Text>{" "}
            {moment(new Date(ha.lastUpdated)).format("MM/DD/YYYY hh:mm A")}
          </Text>
        </View>
      </View>
    </Page>
  );
}

SummaryPage.propTypes = {
  ha: PropTypes.object.isRequired,
  showScores: PropTypes.bool,
};
