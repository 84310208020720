export const filterData = (arr, searchTerm, showInactive) => {
  let filteredArr = searchFilter(arr, searchTerm);
  filteredArr = activeFilter(filteredArr, showInactive);
  return filteredArr;
};

const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => o.name.toLowerCase().includes(searchTerm));
};

const activeFilter = (arr, showInactive) => {
  return arr.filter((o) => (showInactive ? true : o.isActive));
};
