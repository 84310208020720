import * as React from "react";

import { Close, FilterList } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Badge, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";

import PropTypes from "prop-types";

import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../CustomStyles/searchBar";

export default function TableToolbar({
  title,
  renderFilterDialog,
  searchTerm,
  updateSearchTerm,
  getBadgeCount,
  setPage,
}) {
  const [term, setTerm] = React.useState(searchTerm || "");
  const [open, setOpen] = React.useState(false);

  return (
    <Toolbar
      sx={{
        pl: {
          sm: 2,
        },
        pr: {
          xs: 1,
          sm: 1,
        },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
        }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title ?? null}
      </Typography>
      {renderFilterDialog && (
        <>
          <Tooltip title="Filter">
            <Badge badgeContent={getBadgeCount()} color="secondary">
              <IconButton onClick={() => setOpen(true)}>
                <FilterList />
              </IconButton>
            </Badge>
          </Tooltip>
          {renderFilterDialog(open, setOpen, setPage)}
        </>
      )}
      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon
              sx={{
                color:
                  searchTerm && searchTerm !== ""
                    ? "secondary.main"
                    : "#1a1a1a",
              }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Press Enter to Search"
            inputProps={{
              "aria-label": "search",
            }}
            value={term}
            onChange={(event) => setTerm(event.target.value)}
            onBlur={() => {
              updateSearchTerm(term);
              setPage(0);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
            endAdornment={
              searchTerm && searchTerm !== "" ? (
                <IconButton onClick={() => updateSearchTerm("")}>
                  <Close />
                </IconButton>
              ) : null
            }
          />
        </Search>
      </Tooltip>
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  title: PropTypes.string,
  renderFilterDialog: PropTypes.func,
  searchTerm: PropTypes.string,
  updateSearchTerm: PropTypes.func,
  getBadgeCount: PropTypes.func,
  setPage: PropTypes.func,
};
