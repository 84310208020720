import * as React from "react";

import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useMutation } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";

import { MUTATE_TEMPLATE } from "../../../../../graphql/mutations/admin/planning-template";
import { GET_TEMPLATES } from "../../../../../graphql/queries/admin/planning-template";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import EditTemplate from "./EditTemplate";

export default function Row({ row }) {
  const [editTemplate] = useMutation(MUTATE_TEMPLATE, {
    refetchQueries: [{ query: GET_TEMPLATES, fetchPolicy: "no-cache" }],
  });
  const [open, setOpen] = React.useState(false);

  const handleIsActiveChange = (event) => {
    editTemplate({
      variables: {
        input: { id: Number(row.id), isActive: event.target.checked },
      },
    });
  };

  const getAssociatedTo = () => {
    const level = [];
    if (row.ownerSiteProjectTemplates.length > 0) {
      const ospTemplates = row.ownerSiteProjectTemplates.filter(
        (ospt) => ospt.isActive
      );
      ospTemplates.forEach((ospt) => {
        if (ospt.ownerSiteProject.project && !level.includes("Project")) {
          level.push("Project");
        }
        if (
          ospt.ownerSiteProject.site &&
          !ospt.ownerSiteProject.project &&
          !level.includes("Site")
        ) {
          level.push("Site");
        }
        if (
          ospt.ownerSiteProject.owner &&
          !ospt.ownerSiteProject.site &&
          !ospt.ownerSiteProject.project &&
          !level.includes("Company")
        ) {
          level.push("Company");
        }
      });

      return level.join(", ");
    } else {
      return "";
    }
  };

  return (
    <>
      <TableRow hover key={row.id}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>
          {moment(new Date(row.timeCreated)).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell>
          {moment(new Date(row.lastUpdated)).format("MM/DD/YYYY hh:mm A")}
        </TableCell>
        <TableCell>
          {row.lastEditor?.firstName} {row.lastEditor?.lastName}
        </TableCell>
        <TableCell>{row.revisionNumber}</TableCell>
        <TableCell>{getAssociatedTo()}</TableCell>
        <TableCell>
          <CustomSwitch
            checked={row.isActive}
            name={row.id}
            onChange={(event) => handleIsActiveChange(event)}
          />
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="yellow0"
            onClick={() => setOpen(true)}
          >
            VIEW
          </Button>
        </TableCell>
      </TableRow>
      <EditTemplate open={open} handleClose={() => setOpen(false)} row={row} />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
};
