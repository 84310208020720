import React from "react";

import {
  Button,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";

import { useLazyQuery } from "@apollo/client";

import { GET_COMPANY_USERS_SIMPLE } from "../../../../../../graphql/queries/admin/company";
import { GET_USERS_ADMIN } from "../../../../../../graphql/queries/admin/user";
import sortByKeys from "../../../../../../utils/sortByKeys";
import { CustomTextField } from "../../../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../CustomStyles/pageTitle";
import EditUserSupervision from "../Edit/EditUserSupervision";

export default function CreateSupervisorDialog({
  open,
  setOpen,
  company,
  existingSupervisorIds,
}) {
  // Query
  const [
    getCompanyUsers,
    { data: companyUsers, loading: loadingCompanyUsers },
  ] = useLazyQuery(GET_COMPANY_USERS_SIMPLE, { fetchPolicy: "no-cache" });
  const [getUsers, { data: users, loading: loadingUsers }] = useLazyQuery(
    GET_USERS_ADMIN,
    { fetchPolicy: "no-cache" }
  );

  // Local State
  const [choosenUser, setChoosenUser] = React.useState({
    id: null,
    firstName: "",
    lastName: "",
    username: "",
  });

  const handleClose = () => {
    setChoosenUser({
      id: null,
      firstName: "",
      lastName: "",
      username: "",
    });
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      if (company) {
        getCompanyUsers({
          variables: {
            companyId: Number(company.id),
          },
        });
      } else {
        getUsers();
      }
    }
  }, [open]);

  const userOptions = company
    ? companyUsers ?? { users: [] }
    : users ?? { users: [] };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ color: "#333" }}
        onClose={handleClose}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          ADD SUPERVISOR.
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="select-user"
            options={sortByKeys(userOptions.users, "username")}
            value={choosenUser}
            getOptionLabel={(option) =>
              option.company
                ? option.firstName === "" && option.lastName === ""
                  ? `${option.username} [${option.company.name}]`
                  : `${option.username} (${option.firstName} ${option.lastName}) [${option.company.name}]`
                : option.firstName === "" && option.lastName === ""
                ? `${option.username}`
                : `${option.username} (${option.firstName} ${option.lastName})`
            }
            onChange={(event, newVal) => setChoosenUser(newVal)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableClearable
            getOptionDisabled={(option) =>
              existingSupervisorIds.includes(option.id)
            }
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                label="Select User:"
                value={params}
                id={params.id}
              />
            )}
            loading={loadingUsers || loadingCompanyUsers}
          />

          {choosenUser.id !== null ? (
            <EditUserSupervision
              d={choosenUser}
              open={open}
              setOpen={setOpen}
              onClose={handleClose}
            />
          ) : (
            <div style={{ textAlign: "end" }}>
              <Button
                variant="contained"
                style={{
                  marginRight: "10px",
                  marginTop: "30px",
                }}
                onClick={handleClose}
              >
                DONE
              </Button>
            </div>
          )}
        </DialogContent>
      </CustomDialog>
    </>
  );
}
