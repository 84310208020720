export const filterData = (
  arr,
  searchTerm,
  site,
  afterDate,
  beforeDate,
  outcomes,
  status,
  contractor
) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (site.owner.name !== "") {
    filteredArr = siteFilter(filteredArr, site);
  }
  if (afterDate) {
    filteredArr = dateFilter(filteredArr, "after", afterDate);
  }
  if (beforeDate) {
    filteredArr = dateFilter(filteredArr, "before", beforeDate);
  }
  if (outcomes.length > 0) {
    filteredArr = outcomeFilter(filteredArr, outcomes);
  }
  if (status) {
    filteredArr = statusFilter(filteredArr, status);
  }
  if (contractor.name !== "") {
    filteredArr = contractorFilter(filteredArr, contractor);
  }
  return filteredArr;
};

// searchTerm - string
// arr - array of objects
const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) => o.name.toLowerCase().includes(searchTerm));
};

const siteFilter = (arr, site) => {
  return arr.filter((o) =>
    o.ownerSiteProject.site
      ? o.ownerSiteProject.site.id === site.site?.id
      : false
  );
};

const dateFilter = (arr, order, date) => {
  return arr.filter((o) =>
    order === "before" ? new Date(o.date) < date : new Date(o.date) >= date
  );
};

const outcomeFilter = (arr, outcomes) => {
  return arr.filter((o) =>
    outcomes
      .map((o) => o.name)
      .some(
        (filteredOutcome) =>
          o.observationOutcomes
            .map((obsOutcome) => obsOutcome.outcome.name)
            .indexOf(filteredOutcome) >= 0
      )
  );
};

const statusFilter = (arr, status) => {
  return arr.filter((o) => {
    const unplanned = o.automaticCheck || o.manualcheck;
    return status === "Unplanned" && unplanned;
  });
};

const contractorFilter = (arr, contractor) => {
  return arr.filter((o) => o.contractor.name === contractor.name);
};
