import * as React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { GET_CONTRACTOR_INFO } from "../../../../../../../../graphql/queries/admin/company";
import ViewContractor from "../ViewContractor";

export default function Row({ row, company, extraData }) {
  const { data } = useQuery(GET_CONTRACTOR_INFO, {
    variables: {
      ownerId: Number(row.owner.id),
      contractorId: Number(row.contractor.id),
    },
    fetchPolicy: "no-cache",
  });
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(true)}
        key={row.id}
        style={{ cursor: "pointer" }}
      >
        <TableCell component="th" scope="row">
          {row.contractor.name}
        </TableCell>
        <TableCell>
          {
            data?.company.ownerSiteProjectContractors.filter(
              (ospc) =>
                ospc.ownerSiteProject.site && !ospc.ownerSiteProject.project
            ).length
          }
        </TableCell>
        <TableCell>
          {
            data?.company.ownerSiteProjectContractors.filter(
              (ospc) =>
                ospc.ownerSiteProject.site && ospc.ownerSiteProject.project
            ).length
          }
        </TableCell>
      </TableRow>
      <ViewContractor
        open={open}
        setOpen={setOpen}
        id={row.id}
        data={row}
        company={company}
        extraData={extraData}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  company: PropTypes.object,
  extraData: PropTypes.object,
};
