import React from "react";

import PropTypes from "prop-types";

import removeDuplicates from "../../../../../../../utils/removeDuplicates";
import TableTemplate from "../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const initialState = {
    searchterm: "",
    site: { owner: { name: "" } },
    projects: [],
    templates: [],
    status: "",
    incident: null,
    afterDate: null,
    beforeDate: null,
  };
  const [filters, setFilters] = React.useState(initialState);
  const [filteredData, setFilteredData] = React.useState(data);

  const clearFilters = () => {
    setFilters(initialState);
  };

  React.useEffect(() => {
    handleFilter();
  }, [filters.searchterm]);

  const handleFilter = () => {
    setFilteredData(
      filterData(
        data,
        filters.searchterm,
        filters.site,
        filters.afterDate,
        filters.beforeDate,
        filters.projects,
        filters.templates,
        filters.status,
        filters.incident
      )
    );
  };

  const headCells = [
    {
      uid: "name",
      numeric: false,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "Submitter Username",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "company",
      numeric: true,
      disablePadding: false,
      label: "Company",
      sort: true,
    },
    {
      uid: "Location",
      numeric: true,
      disablePadding: false,
      label: "Location",
      sort: false,
    },
    {
      uid: "projects",
      numeric: true,
      disablePadding: false,
      label: "Project(s)",
      sort: false,
    },
    {
      uid: "templates",
      numeric: true,
      disablePadding: false,
      label: "Template(s)",
      sort: false,
    },
    {
      uid: "status",
      numeric: true,
      disablePadding: false,
      label: "HA Status",
      sort: true,
    },
    {
      uid: "revisionNumber",
      numeric: true,
      disablePadding: false,
      label: "Revisions",
      sort: true,
    },
    {
      uid: "incident",
      numeric: true,
      disablePadding: false,
      label: "With or Without Incident",
      sort: true,
    },
    {
      uid: "crewmembers",
      numeric: true,
      disablePadding: false,
      label: "# of Crew Members",
      sort: true,
    },
    {
      uid: "raScore",
      numeric: true,
      disablePadding: false,
      label: "Risk Score",
      sort: true,
    },
  ];

  const getUniqueOwnerSiteProjects = () => {
    const allOwnerSiteProjects = [];
    data.forEach((d) => {
      if (d.haOwnerSiteProject) {
        allOwnerSiteProjects.push(
          ...d.haOwnerSiteProject.map((haosp) => haosp.ownerSiteProject)
        );
      }
    });

    return removeDuplicates(allOwnerSiteProjects, "id");
  };

  const getUniqueSites = () => {
    const uniqueOwnerSiteProjects = getUniqueOwnerSiteProjects();
    return removeDuplicates(uniqueOwnerSiteProjects, "site", "id");
  };

  const getUniqueProjects = () => {
    const uniqueOwnerSiteProjects = getUniqueOwnerSiteProjects();
    return removeDuplicates(uniqueOwnerSiteProjects, "project", "id");
  };

  const getUniqueTemplates = () => {
    const allTemplates = [];
    data.forEach((d) => {
      if (d.haTemplate) {
        allTemplates.push(
          ...d.haTemplate.map((haTemplate) => haTemplate.template)
        );
      }
    });

    return removeDuplicates(allTemplates, "id");
  };

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Hazard Analyses"
      headCells={headCells}
      initialOrderBy="date"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "Submitter Username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "company":
            aItem = a.submitter.company?.name;
            bItem = b.submitter.company?.name;
            break;
          case "status":
            aItem = a.isCompleted ? 1 : 0;
            bItem = b.isCompleted ? 1 : 0;
            break;
          case "incident":
            aItem = a.hasInjury ? "With" : "Without";
            bItem = b.hasInjury ? "With" : "Without";
            break;
          case "linked observations":
            aItem = a.observation.length;
            bItem = b.observation.length;
            break;
          case "crewmembers":
            aItem = a.haCrewMembers.length;
            bItem = b.haCrewMembers.length;
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
          handleFilter={handleFilter}
          clearFilters={clearFilters}
          sites={getUniqueSites()}
          projects={getUniqueProjects()}
          templates={getUniqueTemplates()}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchTerm) =>
        setFilters((prev) => ({ ...prev, searchterm: searchTerm }))
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};
