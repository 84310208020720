import * as React from "react";

import { CircularProgress, Grid } from "@mui/material";

import { useQuery } from "@apollo/client";

import { GET_USERS_ADMIN } from "../../../../graphql/queries/admin/user";
import TableTemplate from "../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table() {
  const { data, loading } = useQuery(GET_USERS_ADMIN, {
    fetchPolicy: "no-cache",
  });

  const onGroupChange = (event, newValue) => {
    setGroupsFilter(newValue || []);
  };

  const onCompanyChange = (event, newValue) => {
    setCompanyFilter(newValue || { name: "" });
  };

  const clearFilters = () => {
    setGroupsFilter([]);
    setCompanyFilter({ name: "" });
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [groupsFilter, setGroupsFilter] = React.useState([]);
  const [companyFilter, setCompanyFilter] = React.useState({ name: "" });

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(data?.users ?? [], searchTerm, groupsFilter, companyFilter)
    );
  }, [data, searchTerm, groupsFilter, companyFilter]);

  return (
    <>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <TableTemplate
          hasPagination={true}
          data={filteredData}
          title="Users"
          headCells={[
            {
              uid: "username",
              numeric: true,
              disablePadding: false,
              label: "Username",
              sort: true,
            },
            {
              uid: "name",
              numeric: false,
              disablePadding: false,
              label: "Name",
              sort: true,
            },
            {
              uid: "company",
              numeric: true,
              disablePadding: false,
              label: "Company",
              sort: true,
            },
            {
              uid: "groups",
              numeric: true,
              disablePadding: false,
              label: "Groups",
              sort: false,
            },
          ]}
          initialOrderBy="username"
          getItemsFromOrderBy={(a, b, orderBy) => {
            let aItem, bItem;

            switch (orderBy) {
              case "username":
                aItem = a.username;
                bItem = b.username;
                break;
              case "name":
                aItem = a.firstName + " " + a.lastName;
                bItem = b.firstName + " " + b.lastName;
                break;
              case "company":
                aItem = a.company ? a.company.name : "";
                bItem = b.company ? b.company.name : "";
                break;
            }

            return { aItem, bItem };
          }}
          renderFilterDialog={(open, setOpen) => (
            <TableFilterDialog
              open={open}
              setOpen={setOpen}
              groupsFilter={groupsFilter}
              onGroupChange={onGroupChange}
              companyFilter={companyFilter}
              onCompanyChange={onCompanyChange}
              clearFilters={clearFilters}
            />
          )}
          searchTerm={searchTerm}
          updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
          getBadgeCount={() => {}}
          renderRow={(row) => <Row key={row.id} row={row} />}
        />
      )}
    </>
  );
}
