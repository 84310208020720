import React from "react";

import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

import RiskSection from "./RiskSection";
import { styles } from "./styles";

export default function ActionStep({ action, ha }) {
  const getUniqueRisks = (arr) =>
    arr.reduce((results, item) => {
      if (
        item.arm.r &&
        !results.some(
          (i) =>
            i.arm.r?.name === item.arm.r.name && i.arm.r?.id === item.arm.r.id
        )
      ) {
        results.push(item);
      }
      return results;
    }, []);

  const findRisks = (a) => {
    const allRisks = ha.haArms.filter(
      (oa) => oa.arm.a.name === a.name && oa.arm.a.id === a.id
    );

    return getUniqueRisks(allRisks);
  };

  return (
    <View style={[styles.row, { borderTop: "1px solid black" }]}>
      <View
        style={{
          flex: 1,
        }}
      >
        <Text style={[styles.text, { textAlign: "center" }]}>
          {action.arm.a.name}
        </Text>
      </View>
      <View style={{ flex: 2 }}>
        <RiskSection risks={findRisks(action.arm.a)} ha={ha} />
      </View>
    </View>
  );
}

ActionStep.propTypes = {
  action: PropTypes.object,
  ha: PropTypes.object,
};
