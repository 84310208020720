import React from "react";

import { Grid, TextField, Autocomplete } from "@mui/material";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { createAuditVar } from "../../../graphql/localVariables/audit";
import { onlineVar } from "../../../graphql/localVariables/user";
import { auditMutations } from "../../../graphql/mutations";
import { GET_AUDITS } from "../../../graphql/queries/audits";
import sortByKeys from "../../../utils/sortByKeys";

export default function AuditTypeView({ setStartAudit }) {
  const updateAudit = useReactiveVar(createAuditVar);
  const { updateCreateAudit } = auditMutations;
  const online = useReactiveVar(onlineVar);
  const { data } = useQuery(GET_AUDITS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const handleChange = (event, newValue) => {
    updateCreateAudit("audit", newValue);
    if (updateAudit.location) {
      setStartAudit(true);
    }
  };

  return (
    <Grid item key={"name"} xs={12}>
      <Autocomplete
        disablePortal
        id="select-audit-type"
        options={data?.audits ? sortByKeys(data.audits, "name") : []}
        value={updateAudit.audit ? updateAudit.audit : { name: "" }}
        getOptionLabel={(option) => {
          return option.name === undefined ? option : option.name;
        }}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          option.name === value.name || option.name === value
        }
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={"Which type of audit would you like to perform?"}
          />
        )}
      />
    </Grid>
  );
}

AuditTypeView.propTypes = {
  setStartAudit: PropTypes.func.isRequired,
};
