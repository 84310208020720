import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { MUTATE_CLASSIFICATION } from "../../../graphql/mutations/admin/industry";
import { GET_CLASSIFICATIONS } from "../../../graphql/queries/admin/industry";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../CustomStyles/LightTextField";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";

export default function CreateClassification() {
  const [createClassification] = useMutation(MUTATE_CLASSIFICATION, {
    onCompleted() {
      onClear();
      setOpen(false);
      onCompletedFunc("Classification has been created");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_CLASSIFICATIONS }],
  });
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [code, setCode] = React.useState("");

  const onSave = () => {
    createClassification({ variables: { name, code } });
  };

  const onCancel = () => {
    setOpen(false);
    onClear();
  };

  const onClear = () => {
    setName("");
    setCode("");
  };

  return (
    <>
      <Button
        variant="contained"
        color="yellow0"
        onClick={() => setOpen(true)}
        sx={{ mr: 2 }}
      >
        CREATE CLASSIFICATION
      </Button>

      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          CREATE CLASSIFICATION.
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                label="Classification Name:"
                variant="standard"
                value={name}
                onChange={(event) => setName(event.target.value)}
                inputProps={{ maxLength: 100 }}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Classification Code:"
                variant="standard"
                value={code}
                onChange={(event) => setCode(event.target.value)}
                inputProps={{ maxLength: 4 }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={onSave}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onCancel}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
