import React from "react";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    border: "3px solid black",
    justifyContent: "center",
    margin: "0px auto",
  },
  formSection: { width: "25%", border: "1px solid black", padding: "10px" },
  armSectionHeader: { width: "50%", borderBottom: "1px solid black" },
  armSectionHeaderMit: { width: "33.3%", borderBottom: "1px solid black" },
  armSection: {
    width: "100%",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
  },
  column: { display: "flex", flexDirection: "column" },
  row: { width: "100%", display: "flex", flexDirection: "row" },
  text: { padding: "20px", fontSize: "10px" },
  sectionLabel: { paddingBottom: "10px", fontSize: "12px" },
  labelText: { padding: "10px", fontSize: "12px", color: "grey" },
  mainText: {
    padding: "10px",
    fontSize: "14px",
    width: "90%",
    borderBottom: "1px solid grey",
  },
});

export default function TemplatePDF({ template, arms, risks, mit, viewMit }) {
  const findRisks = (action) => {
    return risks?.companyArms
      ? risks.companyArms.filter(
          (carm) =>
            Number(carm.arm.a.id) === Number(action.id) && carm.arm.isExpected
        )
      : [];
  };

  const findMit = (action, risk) => {
    return mit?.companyArms
      ? mit.companyArms.filter(
          (carm) =>
            Number(carm.arm.a.id) === Number(action.id) &&
            Number(carm.arm.r.id) === Number(risk.id)
        )
      : [];
  };

  return (
    <Document title="Template" author="NIXN">
      <Page size="A4" wrap>
        <View
          style={{
            padding: "10px",
            borderTop: "3px solid grey",
            margin: "20px auto",
            width: "95%",
          }}
        >
          <Text>TEMPLATE</Text>
        </View>
        <View style={styles.page}>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          >
            <Text style={styles.sectionLabel}>Template Name</Text>
            <Text style={{ fontSize: "12px" }}>{template.name}</Text>
          </View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          >
            <Text style={styles.sectionLabel}>Date Created</Text>
            <Text style={{ fontSize: "12px" }}>
              {moment(new Date(template.timeCreated)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </Text>
          </View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          >
            <Text style={styles.sectionLabel}>Date Last Updated</Text>
            <Text style={{ fontSize: "12px" }}>
              {moment(new Date(template.lastUpdated)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </Text>
          </View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          >
            <Text style={styles.sectionLabel}>Last Edited By</Text>
            <Text style={{ fontSize: "12px" }}>
              {template.lastEditor
                ? template.lastEditor?.firstName !== "" &&
                  template.lastEditor?.lastName !== ""
                  ? `${template.lastEditor?.firstName} ${template.lastEditor?.lastName}`
                  : template.lastEditor.username
                : "N/A"}
            </Text>
          </View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          >
            <Text style={styles.sectionLabel}>Start Date</Text>
            <Text style={{ fontSize: "12px" }}>
              {moment(template.startDate).format("MM/DD/YYYY")}
            </Text>
          </View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          >
            <Text style={styles.sectionLabel}>End Date</Text>
            <Text style={{ fontSize: "12px" }}>
              {moment(template.endDate).format("MM/DD/YYYY")}
            </Text>
          </View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          ></View>
          <View
            style={[
              styles.formSection,
              { borderTop: "none", borderLeft: "none" },
            ]}
          ></View>

          <View
            style={{
              width: "100%",
              height: "20px",
              backgroundColor: "black",
            }}
          ></View>
          <View
            style={[
              viewMit ? styles.armSectionHeaderMit : styles.armSectionHeader,
              { borderRight: "1px solid black" },
            ]}
          >
            <Text
              style={{
                padding: "20px",
                fontSize: "12px",
                borderTop: "5px solid #8297A0",
              }}
            >
              Job Step
            </Text>
          </View>
          <View
            style={[
              viewMit ? styles.armSectionHeaderMit : styles.armSectionHeader,
              { borderRight: "1px solid black" },
            ]}
          >
            <Text
              style={{
                padding: "20px",
                fontSize: "12px",
                borderTop: "5px solid #8297A0",
              }}
            >
              Expected Risks
            </Text>
          </View>
          {viewMit && (
            <View
              style={[
                styles.armSectionHeaderMit,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text
                style={{
                  padding: "20px",
                  fontSize: "12px",
                  borderTop: "5px solid #8297A0",
                }}
              >
                Mitigators
              </Text>
            </View>
          )}
          <View style={[styles.column, { width: "100%" }]}>
            {arms.map((action) => {
              return (
                <View key={action.arm.a.name} style={styles.row}>
                  <View
                    style={[
                      viewMit
                        ? styles.armSectionHeaderMit
                        : styles.armSectionHeader,
                    ]}
                  >
                    <Text
                      style={[styles.text, { textAlign: "center" }]}
                      wrap={false}
                    >
                      {action.arm.a.name}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.column,
                      {
                        width: viewMit ? "66.6%" : "50%",
                      },
                    ]}
                  >
                    {findRisks(action.arm.a).length === 0 && (
                      <View style={[styles.row]}>
                        <View style={[styles.armSection]}>
                          <Text
                            style={[
                              styles.text,
                              {
                                textAlign: "center",
                              },
                            ]}
                            wrap={false}
                          >
                            {" "}
                          </Text>
                        </View>
                      </View>
                    )}
                    {findRisks(action.arm.a).map((carm) => {
                      const risk = carm.arm.r;
                      return (
                        <View key={risk.name} style={[styles.row]}>
                          <View style={[styles.armSection]}>
                            <Text
                              style={[
                                styles.text,
                                {
                                  textAlign: "center",
                                },
                              ]}
                              wrap={false}
                            >
                              {risk.name}
                            </Text>
                          </View>
                          {viewMit && (
                            <View style={[styles.armSection]}>
                              {findMit(action.arm.a, risk).map((carm) => {
                                const mit = carm.arm.m;
                                return (
                                  <Text
                                    key={mit.id}
                                    style={[
                                      styles.text,
                                      {
                                        textAlign: "center",
                                      },
                                    ]}
                                  >
                                    {mit.name}
                                  </Text>
                                );
                              })}
                            </View>
                          )}
                        </View>
                      );
                    })}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
}
