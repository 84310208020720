import React from "react";

import { Edit } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { useMutation } from "@apollo/client";

import { MUTATE_CATEGORY } from "../../../../graphql/mutations/admin/arm";
import { GET_CATEGORIES } from "../../../../graphql/queries/admin/arm";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";

export default function EditCategory({ category }) {
  const [mutateCategory] = useMutation(MUTATE_CATEGORY, {
    onCompleted() {
      setOpen(false);
      onCompletedFunc("Category has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_CATEGORIES }],
  });

  const [open, setOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (category.name !== "") {
      setIsActive(category.isActive);
      setCategoryName(category.name);
    }
  }, [category]);

  const onSaveEdit = () => {
    mutateCategory({
      variables: { input: { id: category.id, name: categoryName, isActive } },
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="yellow0"
        style={{ zIndex: 1000 }}
        endIcon={<Edit />}
        onClick={handleOpen}
      >
        EDIT
      </Button>

      <CustomDialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogTitle style={{ ...pageTitleStyles }}>EDIT CATEGORY.</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs={12} container alignItems="center">
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={isActive}
                    onChange={(event) => setIsActive(event.target.checked)}
                  />
                }
                label={isActive ? "ACTIVE" : "INACTIVE"}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                variant="standard"
                value={categoryName}
                label="Category Name:"
                onChange={(event) => setCategoryName(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="yellow0"
            sx={{ mr: 1 }}
            onClick={onSaveEdit}
          >
            SAVE
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
