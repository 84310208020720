export const filterData = (arr, searchTerm, site, afterDate, beforeDate) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (site.owner.name !== "") {
    filteredArr = siteFilter(filteredArr, site);
  }
  if (afterDate) {
    filteredArr = dateFilter(filteredArr, "after", afterDate);
  }
  if (beforeDate) {
    filteredArr = dateFilter(filteredArr, "before", beforeDate);
  }
  return filteredArr;
};

const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((audit) =>
    `${audit.submitter.username} ${audit.audit.name}`
      .toLowerCase()
      .includes(searchTerm)
  );
};

const siteFilter = (arr, site) => {
  return arr.filter((audit) =>
    audit.ownerSiteProject ? audit.ownerSiteProject.id === site.id : false
  );
};

const dateFilter = (arr, order, date) => {
  return arr.filter((audit) =>
    order === "before"
      ? new Date(audit.date) < date
      : new Date(audit.date) >= date
  );
};
