import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useQuery, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  createIncidentVar,
  editIncidentVar,
} from "../../../../graphql/localVariables/investigation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { investigationMutations } from "../../../../graphql/mutations";
import { GET_HA } from "../../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import { GET_HA_FOR_INCIDENT } from "../../../../graphql/queries/incidentInvestigations";

export default function HA({ type }) {
  // Global Variables
  const editObs = useReactiveVar(editIncidentVar);
  const createObs = useReactiveVar(createIncidentVar);
  const form = type === "EDIT" ? editObs : createObs;
  const online = useReactiveVar(onlineVar);
  // Queries
  const { data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: online ? "cache-first" : "cache-only",
  });
  const { data, loading: loadingHAs } = useQuery(GET_HA_FOR_INCIDENT, {
    variables: { first: 150 },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getHA, { data: ha }] = useLazyQuery(GET_HA, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const [HAs, setHAs] = React.useState([]);

  // Mutations
  const { updateKeyEditIncident, updateKeyCreateIncident } =
    investigationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditIncident : updateKeyCreateIncident;

  const onHAUpdate = (event, value) => {
    if (value) {
      updateObsKeyFunction("ha", value);
      // Fill in auto completes
      if (value.haOwnerSiteProject && value.haOwnerSiteProject.length !== 0) {
        let startTime = moment();

        if (value.time) {
          const timeArr = value.time.split(":");
          startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));
        }

        const osp = value.haOwnerSiteProject[0].ownerSiteProject;
        updateObsKeyFunction("ospId", osp.id);
        updateObsKeyFunction("owner", osp.owner);
        updateObsKeyFunction("site", osp.site);
        updateObsKeyFunction(
          "crewMembers",
          value.haCrewMembers.length !== 0
            ? value.haCrewMembers.map((c) => c.crewMember)
            : []
        );
        updateObsKeyFunction("employee", "");
        updateObsKeyFunction("injuryDate", value.date ? value.date : "");
        updateObsKeyFunction("injuryTime", value.time ? startTime : new Date());
        updateObsKeyFunction(
          "location",
          `${osp.site.name} [${osp.owner.name}]`
        );
        if (value.haOwnerSiteProject.length === 1) {
          updateObsKeyFunction("project", osp.project);
        }
      } else {
        updateObsKeyFunction("ospId", null);
        updateObsKeyFunction("location", "");
        updateObsKeyFunction("owner", { name: "" });
        updateObsKeyFunction("site", { name: "" });
        updateObsKeyFunction("project", { name: "" });
        updateObsKeyFunction("contractor", { name: "" });
      }
      getHA({ variables: { id: Number(value.haId) } });
    } else {
      updateObsKeyFunction("ha", { name: "", date: "" });
    }
  };

  React.useEffect(() => {
    if (ha) {
      updateObsKeyFunction("ha", ha.hazardAnalysis);
    }
  }, [ha]);

  React.useEffect(() => {
    if (data && user) {
      let ha = data.hazardAnalyses.edges
        ? data.hazardAnalyses.edges
            .map((e) => e.node)
            .sort((a, b) => (a.date < b.date ? 1 : -1))
        : [];

      // only show today's HAs if in Construction classification
      if (
        user.currentUser.company.industry &&
        user.currentUser.company.industry.industrialClassification.length > 0 &&
        user.currentUser.company.industry.industrialClassification
          .map((ic) => ic.classification.name)
          .join(" ")
          .includes("Construction")
      ) {
        setHAs(
          ha.filter((ha) => ha.date === moment(new Date()).format("YYYY-MM-DD"))
        );
      } else {
        setHAs(ha);
      }
    }
  }, [data, user]);

  return (
    <Autocomplete
      disablePortal
      id="inc-select-ha"
      options={HAs}
      getOptionLabel={(option) => {
        if (option.name) {
          return `${option.name} [${option.date}]`;
        } else {
          return "";
        }
      }}
      value={form.ha || { name: "", date: "" }}
      onChange={onHAUpdate}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.name}-${option.id}`}>
            {option.name} [{option.date}]
          </li>
        );
      }}
      loading={loadingHAs}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Hazard Analysis:"
          value={params}
          id={params.id}
        />
      )}
    />
  );
}
