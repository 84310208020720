import * as React from "react";

import { useQuery } from "@apollo/client";

import { COMPANY_DASHBOARD } from "../../../../../graphql/queries/admin/company";
import TableTemplate from "../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table() {
  const { data, loading } = useQuery(COMPANY_DASHBOARD, {
    fetchPolicy: "no-cache",
  });

  const onContractorChange = (event, newValue) => {
    setContractorFilter(newValue || "");
  };

  const onNixnUserChange = (event, newValue) => {
    setNixnUserFilter(newValue || "");
  };

  const onActiveChange = (event, newValue) => {
    setActiveFilter(newValue || "");
  };

  const clearFilters = () => {
    setContractorFilter("");
    setNixnUserFilter("");
    setActiveFilter("");
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [contractor, setContractorFilter] = React.useState("");
  const [nixnuser, setNixnUserFilter] = React.useState("");
  const [active, setActiveFilter] = React.useState("");

  const [filteredData, setFilteredData] = React.useState(
    data ? data.companies : []
  );

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        data?.companies ?? [],
        searchTerm,
        contractor,
        nixnuser,
        active
      )
    );
  }, [searchTerm, contractor, nixnuser, active, data]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      loading={loading}
      title="Companies"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Company Name",
          sort: true,
        },
        {
          uid: "contractor",
          numeric: false,
          disablePadding: false,
          label: "Contractor",
        },
        {
          uid: "numSites",
          numeric: true,
          disablePadding: false,
          label: "Number of Sites",
        },
        {
          uid: "numProjects",
          numeric: true,
          disablePadding: false,
          label: "Number of Projects",
        },
        {
          uid: "numUsers",
          numeric: true,
          disablePadding: false,
          label: "Number of Users",
        },
        {
          uid: "nixnUser",
          numeric: false,
          disablePadding: false,
          label: "NIXN User",
        },
        {
          uid: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a[orderBy].toLowerCase();
          bItem = b[orderBy].toLowerCase();
        } else {
          aItem = a[orderBy];
          bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          contractor={contractor}
          onContractorChange={onContractorChange}
          nixnuser={nixnuser}
          onNixnUserChange={onNixnUserChange}
          active={active}
          onActiveChange={onActiveChange}
          clearFilters={clearFilters}
        />
      )}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}
