import React from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../graphql/localVariables/user";
import { MUTATE_MITIGATOR } from "../../../../graphql/mutations/admin/arm";
import { GET_GLOBAL_MIT } from "../../../../graphql/queries/admin/arm";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import {
  onErrorFunc,
  onCompletedFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../CustomComponents/Switch";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";

export default function MitigatorPage({
  mitigator: originalMitigator,
  handleClose,
}) {
  const permissions = useReactiveVar(permissionsVar);

  const { data: user } = useQuery(GET_CURRENT_USER);

  const [mutateMitigator] = useMutation(MUTATE_MITIGATOR, {
    onCompleted() {
      onCompletedFunc("Mitigator has been updated");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [{ query: GET_GLOBAL_MIT }],
  });

  const [canEdit, setCanEdit] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      setCanEdit(user.currentUser.isStaff);
    }
  }, [user]);

  // MITIGATOR STATE
  const [mitigator, setMitigator] = React.useState({ name: "" });
  const [isActive, setIsActive] = React.useState(false);
  const [mitigatorName, setMitigatorName] = React.useState("");

  React.useEffect(() => {
    if (originalMitigator) {
      setMitigator(originalMitigator);
    }
  }, [originalMitigator]);

  React.useEffect(() => {
    if (mitigator.id) {
      setIsActive(mitigator.isActive);
      setMitigatorName(mitigator.name);
    }
  }, [mitigator]);

  const onSave = () => {
    mutateMitigator({
      variables: {
        id: Number(mitigator.id),
        name: mitigatorName,
        isActive,
      },
    });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  return (
    <>
      <DialogContent>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "#8297A0",
              textAlign: "center",
              borderRadius: "6px",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "500" }}>
              Currently editing global mitigator. Only allowed by MAC Safety
              users.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              style={{
                ...pageTitleStyles,
              }}
            >
              MITIGATOR:{" "}
              <span style={{ color: "#FFB700" }}>{mitigator.name}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} container sx={{ mb: 2 }} spacing={2}>
            <Grid item xs={12} container alignItems="center" sx={{ mt: 3 }}>
              {permissions.includes("ARM") && (
                <Grid item container alignItems="center" xs={12}>
                  <FormControlLabel
                    style={{ color: "white" }}
                    control={
                      <CustomSwitch
                        checked={isActive}
                        onChange={(event) => setIsActive(event.target.checked)}
                        disabled={!canEdit}
                      />
                    }
                    label={isActive ? "ACTIVE" : "INACTIVE"}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomTextField
                  variant="standard"
                  value={mitigatorName}
                  label="Mitigator Name:"
                  onChange={(event) => setMitigatorName(event.target.value)}
                  fullWidth
                  multiline
                  disabled={!canEdit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
        >
          SAVE
        </Button>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </>
  );
}
