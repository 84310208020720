export const filterData = (
  arr,
  searchTerm,
  site,
  afterDate,
  beforeDate,
  project,
  template,
  status,
  incident
) => {
  let filteredArr = searchFilter(arr, searchTerm);
  if (site.owner.name !== "") {
    filteredArr = siteFilter(filteredArr, site);
  }
  if (afterDate) {
    filteredArr = dateFilter(filteredArr, "after", afterDate);
  }
  if (beforeDate) {
    filteredArr = dateFilter(filteredArr, "before", beforeDate);
  }
  if (project.length > 0) {
    filteredArr = projectFilter(filteredArr, project);
  }
  if (template.length > 0) {
    filteredArr = templateFilter(filteredArr, template);
  }
  if (status !== "") {
    filteredArr = statusFilter(filteredArr, status);
  }
  if (incident !== undefined) {
    filteredArr = incidentFilter(filteredArr, incident);
  }
  return filteredArr;
};

const searchFilter = (arr, searchTerm) => {
  searchTerm = String(searchTerm).toLowerCase();
  return arr.filter((o) =>
    `${o.submitter.firstName} ${o.submitter.lastName} ${o.submitter.username} ${o.name}`
      .toLowerCase()
      .includes(searchTerm)
  );
};

const siteFilter = (arr, site) => {
  return arr.filter((o) =>
    o.haOwnerSiteProject && o.haOwnerSiteProject.length > 0
      ? o.haOwnerSiteProject[0].ownerSiteProject.site.id === site.site.id
      : false
  );
};

const dateFilter = (arr, order, date) => {
  return arr.filter((o) =>
    order === "before" ? new Date(o.date) < date : new Date(o.date) >= date
  );
};

const statusFilter = (arr, status) => {
  return arr.filter((o) =>
    status === "Closed" ? o.isCompleted : !o.isCompleted
  );
};

const projectFilter = (arr, projects) => {
  const projIds = projects.map((p) => p.id);
  return arr.filter(
    (o) =>
      o.haOwnerSiteProject.findIndex((osp) =>
        projIds.includes(osp.ownerSiteProject.project.id)
      ) >= 0
  );
};

const templateFilter = (arr, templates) => {
  const tempIds = templates.map((t) => t.id);
  return arr.filter(
    (o) =>
      o.haTemplate.findIndex((haT) => tempIds.includes(haT.template.id)) >= 0
  );
};

const incidentFilter = (arr, incident) => {
  return arr.filter((o) => (incident === "With" ? o.hasInjury : !o.hasInjury));
};
