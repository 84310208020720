import React from "react";

import PropTypes from "prop-types";

import removeDuplicates from "../../../../../../../utils/removeDuplicates";
import TableTemplate from "../../../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const initialState = {
    searchterm: "",
    site: { owner: { name: "" } },
    contractor: { name: "" },
    afterDate: null,
    beforeDate: null,
    outcomes: [],
    status: "",
  };
  const [filters, setFilters] = React.useState(initialState);
  const [filteredData, setFilteredData] = React.useState(data);

  const clearFilters = () => {
    setFilters(initialState);
  };

  const handleFilter = () => {
    setFilteredData(
      filterData(
        data,
        filters.searchterm,
        filters.site,
        filters.afterDate,
        filters.beforeDate,
        filters.outcomes,
        filters.status,
        filters.contractor
      )
    );
  };

  React.useEffect(() => {
    handleFilter();
  }, [filters.searchterm]);

  const headCells = [
    {
      uid: "name",
      numeric: false,
      disablePadding: false,
      label: "OBS ID",
      sort: true,
    },
    {
      uid: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
      sort: true,
    },
    {
      uid: "outcome",
      numeric: true,
      disablePadding: false,
      label: "Outcome",
      sort: false,
    },
    {
      uid: "Submitter Username",
      numeric: true,
      disablePadding: false,
      label: "Submitter Username",
      sort: true,
    },
    {
      uid: "Contractor Observed",
      numeric: true,
      disablePadding: false,
      label: "Contractor Observed",
      sort: true,
    },
    {
      uid: "Planned Status",
      numeric: true,
      disablePadding: false,
      label: "Planned Status",
      sort: false,
    },
    {
      uid: "Location",
      numeric: true,
      disablePadding: false,
      label: "Location",
      sort: false,
    },
    {
      uid: "HA Name",
      numeric: true,
      disablePadding: false,
      label: "HA Name",
      sort: true,
    },
  ];

  const getUniqueOwnerSiteProjects = () => {
    const allOwnerSiteProjects = data
      .filter((d) => d.ownerSiteProject)
      .map((d) => d.ownerSiteProject);
    return removeDuplicates(allOwnerSiteProjects, "id");
  };

  const getUniqueSites = () => {
    const uniqueOwnerSiteProjects = getUniqueOwnerSiteProjects();
    return removeDuplicates(uniqueOwnerSiteProjects, "site", "id");
  };

  const getUniqueOutcomes = () => {
    const allOutcomes = [];
    data.forEach((d) => {
      if (d.observationOutcomes) {
        allOutcomes.push(
          ...d.observationOutcomes.map((obsOutcomes) => obsOutcomes.outcome)
        );
      }
    });

    return removeDuplicates(allOutcomes, "name");
  };

  const getUniqueContractors = () => {
    const allContractors = data
      .filter((d) => d.contractor)
      .map((d) => d.contractor);
    return removeDuplicates(allContractors, "id");
  };

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Observations"
      headCells={headCells}
      initialOrderBy="name"
      initialSort="desc"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        switch (orderBy) {
          case "Submitter Username":
            aItem = a.submitter.username;
            bItem = b.submitter.username;
            break;
          case "HA Name":
            aItem = a.ha ? a.ha.name : "";
            bItem = b.ha ? b.ha.name : "";
            break;
          case "Contractor Observed":
            aItem = a.contractor ? a.contractor.name : "";
            bItem = b.contractor ? b.contractor.name : "";
            break;
          default:
            aItem = a[orderBy];
            bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
          handleFilter={handleFilter}
          clearFilters={clearFilters}
          sites={getUniqueSites()}
          outcomes={getUniqueOutcomes()}
          contractors={getUniqueContractors()}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchterm) =>
        setFilters((prev) => ({ ...prev, searchterm }))
      }
      getBadgeCount={() => {
        let count = 0;
        if (filters.site && filters.site.owner.name !== "") count++;
        if (filters.contractor && filters.contractor.name !== "") count++;
        if (filters.outcomes.length > 0) count++;
        if (filters.status !== "") count++;
        if (filters.afterDate && filters.afterDate !== null) count++;
        if (filters.beforeDate && filters.beforeDate !== null) count++;
        return count;
      }}
      renderRow={(row) => <Row key={row.id} row={row} />}
    />
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
};
