import { gql } from "@apollo/client";

export const COMPANY_ADMIN_USERS = gql`
  query companyAdminUsers($id: Int) {
    users(isActive: true, company_Id: $id) {
      id
      firstName
      lastName
      username
      image
      email
      supervise {
        id
        isActive
        supervisee {
          id
          firstName
          lastName
          username
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`;

export const COMPANY_ADMIN_CONTRACTED_SITES = gql`
  query companyAdminContractors($id: Int) {
    ownerSiteProjectContractors(
      contractor_Id: $id
      ownerSiteProject_IsActive: true
      ownerSiteProject_Site_Isnull: false
      ownerSiteProject_Project_Isnull: true
      isActive: true
    ) {
      id
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
          addrLine1
          addrLine2
          addrCity
          addrState
          addrZip
          note
        }
        project {
          id
          name
        }
      }
      contractor {
        id
        name
      }
    }
  }
`;

export const COMPANY_ADMIN_OSP = gql`
  query companyAdminOSP($id: Int) {
    ownerSiteProjects(
      owner_Id: $id
      isActive: true
      site_IsActive: true
      isCompanyDashboard: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
      project {
        id
        name
      }
      ownerSiteProjectContractor {
        id
        contractor {
          id
          name
        }
      }
    }
  }
`;

export const COMPANY_ADMIN_SITE_CREWMEMBERS = gql`
  query companyAdminSiteCrew($id: Int) {
    ownerSiteProjectCrewMembers(
      ownerSiteProject_Owner_Id: $id
      isActive: true
    ) {
      id
      crewMember {
        id
        firstName
        lastName
      }
    }
  }
`;

export const COMPANY_ADMIN_CREWMEMBERS = gql`
  query companyAdminCrew($id: Int) {
    crewMembers(company_Id: $id, isActive: true) {
      id
      firstName
      lastName
      phoneNumber
      hireDate
      email
      other
      yrsOfExp {
        id
        name
      }
      drugTestExpiry
      jobTitle {
        id
        name
      }
      crewMemberContact {
        id
        contact {
          id
          name
          phoneNumber
        }
      }
      craftType {
        id
        name
      }
    }
  }
`;

export const COMPANY_ADMIN_TEMPLATES = gql`
  query companyAdminTemplates($id: Int) {
    ownerSiteProjectTemplates(ownerSiteProject_Owner_Id: $id, isActive: true) {
      id
      ownerSiteProject {
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
      template {
        id
        name
        isActive
        timeCreated
        lastUpdated
        startDate
        endDate
        lastEditor {
          id
          firstName
          lastName
        }
        revisionNumber
      }
    }
  }
`;
