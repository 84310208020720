import React from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { useMutation, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { permissionsVar } from "../../../graphql/localVariables/user";
import {
  CREATE_ACTION,
  CREATE_CATEGORY,
  CREATE_MITIGATOR,
  CREATE_RISK,
} from "../../../graphql/mutations/admin/arm";
import {
  GET_CATEGORIES,
  GET_COMPANY_ARMS_ADMIN,
} from "../../../graphql/queries/admin/arm";
import removeDuplicates from "../../../utils/removeDuplicates";
import sortByKeys from "../../../utils/sortByKeys";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../CustomComponents/OnErrorFunction";
import TabPanel from "../../CustomComponents/TabPanels";
import { StyledTab, StyledTabs } from "../../CustomComponents/Tabs";
import { CustomDialog } from "../../CustomStyles/dialog";
import { pageTitleStyles } from "../../CustomStyles/pageTitle";
import SpecifyCompany from "./SpecifyCompany";

export default function CreateARM({
  open,
  handleClose,
  categories,
  actions,
  risks,
  currentUser,
  companySelected,
}) {
  const permissions = useReactiveVar(permissionsVar);
  const [createCategory, { loading: loadingCreateCategory }] = useMutation(
    CREATE_CATEGORY,
    {
      onCompleted() {
        onCompletedFunc("Category has been created");
        setCreateCategoryName("");
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [{ query: GET_CATEGORIES }],
    }
  );
  const [createAction, { loading: loadingCreateAction }] = useMutation(
    CREATE_ACTION,
    {
      onCompleted() {
        onCompletedFunc("Action has been created");
        setCreateActionCategory([]);
        setCreateActionName("");
        setActionChildCompanies(true);
        setActionAssignedCompany({ name: "" });
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: GET_COMPANY_ARMS_ADMIN,
          variables: {
            companyId: companySelected
              ? Number(companySelected)
              : Number(currentUser.currentUser.company.id),
            rNull: true,
            mNull: true,
          },
        },
      ],
    }
  );
  const [createRisk, { loading: loadingCreateRisk }] = useMutation(
    CREATE_RISK,
    {
      onCompleted() {
        onCompletedFunc("Risk has been created");
        setCreateRiskAction({ a: { name: "" } });
        setCreateRiskName("");
        setRiskChildCompanies(true);
        setRiskAssignedCompany({ name: "" });
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: GET_COMPANY_ARMS_ADMIN,
          variables: {
            companyId: companySelected
              ? Number(companySelected)
              : Number(currentUser.currentUser.company.id),
            rNull: false,
            mNull: true,
          },
        },
      ],
    }
  );
  const [createMitigator, { loading: loadingCreateMitigator }] = useMutation(
    CREATE_MITIGATOR,
    {
      onCompleted() {
        onCompletedFunc("Mitigator has been created");
        setCreateMitigatorName("");
        setCreateMitigatorAction({ a: { name: "" } });
        setCreateMitigatorRisk({ r: { name: "" } });
        setMitChildCompanies(true);
        setMitAssignedCompany({ name: "" });
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        {
          query: GET_COMPANY_ARMS_ADMIN,
          variables: {
            companyId: companySelected
              ? Number(companySelected)
              : Number(currentUser.currentUser.company.id),
            rNull: false,
            mNull: false,
          },
        },
      ],
    }
  );
  const [value, setValue] = React.useState(0);

  const [createCategoryName, setCreateCategoryName] = React.useState("");

  const [createActionCategory, setCreateActionCategory] = React.useState([]);
  const [createActionName, setCreateActionName] = React.useState("");
  const [actionChildCompanies, setActionChildCompanies] = React.useState(true);
  const [actionAssignedCompany, setActionAssignedCompany] = React.useState({
    name: "",
  });

  const [createRiskAction, setCreateRiskAction] = React.useState({
    a: { name: "" },
  });
  const [createRiskName, setCreateRiskName] = React.useState("");
  const [riskExpected, setRiskExpected] = React.useState(false);
  const [riskChildCompanies, setRiskChildCompanies] = React.useState(true);
  const [riskAssignedCompany, setRiskAssignedCompany] = React.useState({
    name: "",
  });

  const [createMitigatorAction, setCreateMitigatorAction] = React.useState({
    a: { name: "" },
  });
  const [createMitigatorRisk, setCreateMitigatorRisk] = React.useState({
    r: { name: "" },
  });
  const [createMitigatorName, setCreateMitigatorName] = React.useState("");
  const [mitChildCompanies, setMitChildCompanies] = React.useState(true);
  const [mitAssignedCompany, setMitAssignedCompany] = React.useState({
    name: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onCreateCategory = () => {
    if (createCategoryName.replace(/\s/g, "") !== "") {
      createCategory({
        variables: {
          input: { name: titleCase(createCategoryName), isActive: true },
        },
      });
    } else onErrorFunc("Please provide a valid name for this category.");
  };

  function titleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  }

  const onCreateAction = () => {
    if (createActionName.replace(/\s/g, "") !== "") {
      if (actionAssignedCompany.name === "") {
        createAction({
          variables: {
            categories: createActionCategory.map((c) => c.id),
            company: Number(currentUser.currentUser.company.id),
            companyArm_IsApproved: true,
            companyArm_Exclusive: !actionChildCompanies,
            name: titleCase(createActionName),
            isActive: true,
          },
        });
      } else {
        createAction({
          variables: {
            categories: createActionCategory.map((c) => c.id),
            company: Number(actionAssignedCompany.id),
            companyArm_IsApproved: true,
            companyArm_Exclusive: !actionChildCompanies,
            name: titleCase(createActionName),
            isActive: true,
          },
        });
      }
    } else onErrorFunc("Please provide a name for this action.");
  };

  const onCreateRisk = () => {
    if (createRiskName.replace(/\s/g, "") !== "") {
      if (riskAssignedCompany.name === "") {
        createRisk({
          variables: {
            action: Number(createRiskAction.a.id),
            isExpected: riskExpected,
            company: Number(currentUser.currentUser.company.id),
            companyArm_IsApproved: true,
            companyArm_Exclusive: !riskChildCompanies,
            name: titleCase(createRiskName),
          },
        });
      } else {
        createRisk({
          variables: {
            action: Number(createRiskAction.a.id),
            isExpected: riskExpected,
            company: Number(riskAssignedCompany.id),
            companyArm_IsApproved: true,
            companyArm_Exclusive: !riskChildCompanies,
            name: titleCase(createRiskName),
          },
        });
      }
    } else onErrorFunc("Please provide a name for this risk.");
  };

  const onCreateMitigator = () => {
    if (createMitigatorName.replace(/\s/g, "") !== "") {
      if (mitAssignedCompany.name === "") {
        createMitigator({
          variables: {
            action: Number(createMitigatorAction.a.id),
            risk: Number(createMitigatorRisk.r.id),
            company: Number(currentUser.currentUser.company.id),
            companyArm_IsApproved: true,
            companyArm_Exclusive: !mitChildCompanies,
            name: titleCase(createMitigatorName),
          },
        });
      } else {
        createMitigator({
          variables: {
            action: Number(createMitigatorAction.a.id),
            risk: Number(createMitigatorRisk.r.id),
            company: Number(mitAssignedCompany.id),
            companyArm_IsApproved: true,
            companyArm_Exclusive: !mitChildCompanies,
            name: titleCase(createMitigatorName),
          },
        });
      }
    } else onErrorFunc("Please provide a name for this mitigator.");
  };

  const editCategory =
    permissions.includes("CATEGORY") || permissions.includes("EDIT_CATEGORY");

  const getRiskIndex = () => {
    if (editCategory && permissions.includes("ARM")) {
      return 2;
    } else if (editCategory || permissions.includes("ARM")) {
      return 1;
    } else return 0;
  };

  const getMitigatorIndex = () => {
    if (editCategory && permissions.includes("ARM")) {
      return 3;
    } else {
      return 2;
    }
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle style={{ ...pageTitleStyles }}>CREATE ARM.</DialogTitle>
      <DialogContent>
        <Paper>
          <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
            {editCategory && <StyledTab label="CATEGORY" />}
            {permissions.includes("ARM") && <StyledTab label="ACTION" />}
            {permissions.includes("ARM") && <StyledTab label="RISK" />}
            {permissions.includes("ARM") && <StyledTab label="MITIGATOR" />}
          </StyledTabs>
          {editCategory && (
            <TabPanel value={value} index={0}>
              <Grid container direction="column" alignItems="center">
                <TextField
                  label="New Category Name:"
                  variant="standard"
                  sx={{ my: 1 }}
                  fullWidth
                  value={createCategoryName}
                  onChange={(event) =>
                    setCreateCategoryName(event.target.value)
                  }
                  inputProps={{ maxLength: 100 }}
                />
                <LoadingButton
                  variant="contained"
                  color="yellow0"
                  onClick={onCreateCategory}
                  loading={loadingCreateCategory}
                  sx={{ my: 2 }}
                >
                  CREATE
                </LoadingButton>
              </Grid>
            </TabPanel>
          )}
          {permissions.includes("ARM") && (
            <TabPanel value={value} index={editCategory ? 1 : 0}>
              <Grid container direction="column" alignItems="center">
                <Autocomplete
                  disablePortal
                  id="select-action-create-risk"
                  options={
                    categories?.categories
                      ? sortByKeys(
                          categories.categories.filter((c) => c.isActive),
                          "name"
                        )
                      : []
                  }
                  value={createActionCategory}
                  onChange={(event, value) => setCreateActionCategory(value)}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  multiple
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        sx={{ mr: 1 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={"Category (Optional):"}
                    />
                  )}
                />
                <TextField
                  label="New Action Name:"
                  variant="standard"
                  sx={{ mb: 1 }}
                  value={createActionName}
                  onChange={(event) => setCreateActionName(event.target.value)}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                />
                <SpecifyCompany
                  type={"action"}
                  assignedCompany={actionAssignedCompany}
                  setAssignedCompany={setActionAssignedCompany}
                  companySelected={companySelected}
                  currentUser={currentUser}
                />
                <LoadingButton
                  variant="contained"
                  color="yellow0"
                  onClick={onCreateAction}
                  loading={loadingCreateAction}
                  sx={{ my: 2 }}
                >
                  CREATE
                </LoadingButton>
              </Grid>
            </TabPanel>
          )}
          {permissions.includes("ARM") && (
            <TabPanel value={value} index={getRiskIndex()}>
              {actions ? (
                <Grid container direction="column" alignItems="center">
                  <Autocomplete
                    disablePortal
                    id="select-action-create-risk"
                    options={
                      actions.companyArms
                        ? sortByKeys(
                            removeDuplicates(
                              actions.companyArms.map((carm) => carm.arm),
                              "a",
                              "id"
                            ),
                            "a.name"
                          )
                        : []
                    }
                    value={createRiskAction}
                    onChange={(event, value) => setCreateRiskAction(value)}
                    getOptionLabel={(option) => option.a?.name}
                    disableClearable
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={"Action:"}
                      />
                    )}
                  />

                  <TextField
                    label="New Risk Name:"
                    variant="standard"
                    fullWidth
                    sx={{ my: 1 }}
                    value={createRiskName}
                    onChange={(event) => setCreateRiskName(event.target.value)}
                    disabled={createRiskAction.name === ""}
                    inputProps={{ maxLength: 100 }}
                  />
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      checked={riskExpected}
                      onChange={(event) =>
                        setRiskExpected(event.target.checked)
                      }
                    />
                    <Typography>Is Expected</Typography>
                  </Grid>
                  <SpecifyCompany
                    type={"risk"}
                    assignedCompany={riskAssignedCompany}
                    setAssignedCompany={setRiskAssignedCompany}
                    currentUser={currentUser}
                  />
                  <LoadingButton
                    variant="contained"
                    color="yellow0"
                    onClick={onCreateRisk}
                    loading={loadingCreateRisk}
                    sx={{ my: 2 }}
                  >
                    CREATE
                  </LoadingButton>
                </Grid>
              ) : (
                <Grid container justifyContent="center">
                  <CircularProgress color="secondary" />
                </Grid>
              )}
            </TabPanel>
          )}
          {permissions.includes("ARM") && (
            <TabPanel value={value} index={getMitigatorIndex()}>
              {actions && risks ? (
                <Grid container direction="column" alignItems="center">
                  <Autocomplete
                    disablePortal
                    id="select-action-create-mitigator"
                    options={
                      actions.companyArms
                        ? sortByKeys(
                            removeDuplicates(
                              actions.companyArms.map((carm) => carm.arm),
                              "a",
                              "id"
                            ),
                            "a.name"
                          )
                        : []
                    }
                    value={createMitigatorAction}
                    onChange={(event, value) => setCreateMitigatorAction(value)}
                    getOptionLabel={(option) => option.a.name}
                    disableClearable
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={"Action:"}
                      />
                    )}
                  />
                  <Autocomplete
                    disablePortal
                    id="select-risk-create-mitigator"
                    options={
                      risks.companyArms
                        ? sortByKeys(
                            removeDuplicates(
                              risks.companyArms
                                .map((carm) => carm.arm)
                                .filter(
                                  (arm) =>
                                    arm.a.id === createMitigatorAction.a.id
                                ),
                              "r",
                              "id"
                            ),
                            "r.name"
                          )
                        : []
                    }
                    value={createMitigatorRisk}
                    onChange={(event, value) => setCreateMitigatorRisk(value)}
                    getOptionLabel={(option) => option.r.name}
                    disableClearable
                    disabled={createMitigatorAction.a.name === ""}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={"Risk:"}
                      />
                    )}
                  />
                  <TextField
                    label="New Mitigator Name:"
                    variant="standard"
                    fullWidth
                    multiline
                    value={createMitigatorName}
                    onChange={(event) =>
                      setCreateMitigatorName(event.target.value)
                    }
                    sx={{ mb: 2 }}
                    inputProps={{ maxLength: 200 }}
                  />
                  <SpecifyCompany
                    type={"mitigator"}
                    assignedCompany={mitAssignedCompany}
                    setAssignedCompany={setMitAssignedCompany}
                    currentUser={currentUser}
                  />
                  <LoadingButton
                    variant="contained"
                    color="yellow0"
                    onClick={onCreateMitigator}
                    loading={loadingCreateMitigator}
                    sx={{ my: 2 }}
                  >
                    CREATE
                  </LoadingButton>
                </Grid>
              ) : (
                <Grid container justifyContent="center">
                  <CircularProgress color="secondary" />
                </Grid>
              )}
            </TabPanel>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

CreateARM.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  categories: PropTypes.object,
  actions: PropTypes.object,
  risks: PropTypes.object,
  currentUser: PropTypes.object,
  companySelected: PropTypes.object,
};
