import React from "react";

import { FilterList } from "@mui/icons-material";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";

import { CustomSwitch } from "./Switch";

export default function ShowInactiveFilter({
  showInActive,
  setShowInActive,
  color,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <FilterList style={{ color: color ?? "black" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
      >
        <Grid style={{ padding: "20px" }}>
          <Typography>Show Inactive?</Typography>
          <FormControlLabel
            style={{ color: "black" }}
            control={
              <CustomSwitch
                checked={showInActive}
                onChange={(event) => setShowInActive(event.target.checked)}
              />
            }
            label={showInActive ? "YES" : "NO"}
          />
        </Grid>
      </Menu>
    </>
  );
}
