import React, { useState } from "react";

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import { useLazyQuery } from "@apollo/client";

import { GET_CONTRACTOR_INFO } from "../../../../../../../graphql/queries/admin/company";
import { CustomDialog } from "../../../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../../../CustomStyles/pageTitle";
import EditSiteDialog from "../../Edit/EditSiteDialog";

function Contractor({ ospc, company, extraData }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography
        key={ospc.id}
        onClick={() => setOpen(true)}
        sx={{ cursor: "pointer", textDecoration: "underline", my: 2 }}
      >
        {ospc.ownerSiteProject.site.name}
      </Typography>
      <EditSiteDialog
        open={open}
        setOpen={setOpen}
        data={ospc.ownerSiteProject}
        company={company}
        siteData={extraData}
      />
    </>
  );
}

export default function ViewContractor({
  open,
  setOpen,
  data,
  company,
  extraData,
}) {
  const [getQuery, { data: info, loading }] = useLazyQuery(
    GET_CONTRACTOR_INFO,
    {
      variables: {
        ownerId: Number(data.owner.id),
        contractorId: Number(data.contractor.id),
      },
    }
  );

  React.useEffect(() => {
    if (open) {
      getQuery({
        variables: {
          ownerId: Number(data.owner.id),
          contractorId: Number(data.contractor.id),
        },
      });
    }
  }, [open]);

  const onDone = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ ...pageTitleStyles }}>
          CONTRACTOR:{" "}
          <span style={{ color: "#FFB700" }}>{data.contractor.name}</span>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Typography sx={{ fontSize: 22 }}>CONTRACTED SITES:</Typography>
              {info?.company?.ownerSiteProjectContractors
                .filter((ospc) => ospc.ownerSiteProject.site)
                .map((ospc) => (
                  <Contractor
                    key={ospc.id}
                    ospc={ospc}
                    company={company}
                    extraData={extraData}
                  />
                ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={onDone}
          >
            DONE
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
